import React, { useEffect, useRef, useState } from "react";
import "./Game.scss";
import Toolbar from "./toolbar/Toolbar";
import Location from "./location/Location";

import store from "../../services/store.service";
// import CoinDrop from "./base/CoinDrop/CoinDrop";
import { socketConnection, IRequestResponse } from "../../core/api/common";
import {
  setUserActions,
  setUserConfig,
  setUserMainParams,
} from "../../services/store/user/user.store";
import {
  changeLocation,
  setLocationData,
} from "../../services/store/location/location.store";

import { getList } from "../../core/api/items/items.api";

import { loadJson, setToCache } from "../../services/base/list";
import { setBossData, setMobsData } from "../../services/store/mobs/mobs.store";
import { setNpcData } from "../../services/store/npc/npc.store";
import DialogModal from "./base/DialogModal/DialogModal";
import { setItemsData } from "../../services/store/items/items.store";
import {
  getDictionary,
  getServerTime,
  getDungeons,
} from "../../core/api/other/other.api";
import {
  changeEffects,
  updateHeal,
  setServerTime,
} from "../../services/store/other/other.store";
import TopToolbar from "./topToolbar/TopToolbar";
import { useSelector } from "react-redux";
import Chat from "./base/Chat/Chat";
import QuickInfo from "./base/QuickInfo/QuickInfo";
import DisconnectedModal from "./base/DisconnectedModal/DisconnectedModal";
import { initilizeStyles } from "../../services/base/styles";
import { useTelegramWebApp } from "../../services/hooks/isTelegram.hook";

function genItems() {
  let x = 0;
  let y = 0;
  const size = 64;

  return new Array(160).fill(null).reduce((acc, i, id) => {
    acc[id] = {
      backgroundPosition: `-${x}px -${y}px`,
      id,
    };

    if ((id + 1) % 6 === 0) {
      y += size;
      x = 0;
    } else {
      x += size;
    }

    return acc;
  }, {});
}

function Game() {
  const size = useWindowSize();
  const { isChatOpened } = useSelector((store: any) => store.other);
  const [isLoading, changeLoaderStatus] = useState(true);
  const [isDisconnected, setDisconnected] = useState(false);

  useEffect(() => {
    const socket = socketConnection();

    let firstInit = true;
    let effectInterval: any = null;
    let styleCanceledFunc: any = null;

    socket.on("disconnect", (resp: IRequestResponse) => {
      setDisconnected(true);
    });

    socket.on("get effects", (resp: IRequestResponse) => {
      if (resp.hasError) return;
      const effectList = resp.getData();

      effectInterval && clearInterval(effectInterval);
      store.dispatch(changeEffects(effectList));

      effectInterval = setInterval(() => {
        const effects = store.getState().other.effects.map((effect: any) => {
          return {
            ...effect,
            time: effect.time - 1000,
          };
        });

        store.dispatch(changeEffects(effects));
      }, 1000);
    });

    socket.on("get action id", async (resp: IRequestResponse) => {
      if (resp.hasError) {
        location.href = "/login";
        changeLoaderStatus(false);
        return;
      }

      const currentActions = resp.getData();
      const latestActs: any = store.getState().user.acts;

      switch (currentActions.type) {
        case "heal":
          {
            store.dispatch(updateHeal());
          }
          break;
        case "effect": {
          socket.send("get effects");
        }
      }

      if (
        latestActs.mainInfo === undefined ||
        latestActs.mainInfo !== currentActions.mainInfo
      ) {
        store.dispatch(setUserActions(currentActions));
        socket.send("get main info");
      }
    });

    socket.on("get config", async (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }
      store.dispatch(setUserConfig(resp.getData()));
    });

    socket.on("get main info", async (resp: IRequestResponse) => {
      if (resp.hasError) {
        location.href = "/login";
        return;
      }

      const results = resp.getData();
      store.dispatch(setUserMainParams(results));

      if (firstInit) {
        firstInit = false;
        const [effects, mobs, bosses, npc, [levels, village]] = await loadJson([
          "character/effects.json",
          "mobs/mobs.json",
          "mobs/bosses.json",
          "npc/npc.json",
          ["levels/levels.json", "levels/village.json"],
        ]);

        const itemList: IRequestResponse = await getList();
        const dictionary: IRequestResponse = await getDictionary();
        const dungeons: IRequestResponse = await getDungeons();
        const serverTime: IRequestResponse = await getServerTime();

        setToCache("dictionary", dictionary.getData());
        setToCache("dungeons", dungeons.getData());

        styleCanceledFunc = initilizeStyles(
          Object.values(effects),
          Object.values(genItems()),
          Object.values(mobs),
          Object.values(bosses),
          Object.values(npc),
          Object.values({ ...levels, ...village }),
        );

        store.dispatch(setLocationData({ ...levels, ...village }));
        store.dispatch(setMobsData(mobs));
        store.dispatch(setServerTime(serverTime.getData()));
        store.dispatch(setBossData(bosses));

        store.dispatch(setNpcData(npc));
        store.dispatch(setItemsData(itemList.getData()));

        if (Number.isInteger(results.locationId)) {
          store.dispatch(changeLocation(results.locationId));
        }
        changeLoaderStatus(false);
      }
    });

    socket.send("get action id");
    socket.send("get config");
    socket.send("get effects");

    return () => {
      clearInterval(effectInterval);
      socket.off("get action id");
      socket.off("get config");
      socket.off("get effects");
      socket.off("get main info");
      socket?.disconnect();
      if (styleCanceledFunc) styleCanceledFunc();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <>
          <div className="game-container">
            <div
              className="game"
              style={{
                height: `${size.height}`,
                transform: `${size.transform}`,
                width: `${size.width}`,
              }}
            >
              <TopToolbar />

              <Location></Location>

              <Toolbar></Toolbar>

              {/* <div className="damage-pane"><AttackBar></AttackBar></div> */}
              {isDisconnected ? <DisconnectedModal /> : null}
              <DialogModal />
              {isChatOpened ? <Chat /> : null}
              {/* <CoinDrop></CoinDrop> */}
              <QuickInfo></QuickInfo>

              <div id="portal-root"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function useWindowSize() {
  const isTelegramApp = useTelegramWebApp();
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    scaleX: 1,
    scaleY: 1,
    transform: "matrix(1, 0, 0, 1, 0, 0)",
    width: window.innerWidth,
  });

  useEffect(() => {
    const designWidth = 375;
    const designHeight = 650;

    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      let scaleX = width / designWidth;
      let scaleY = height / designHeight;

      // Определяем масштабирование
      // if (!isTelegramApp) {
      if (width / height < designWidth / designHeight) {
        scaleY = scaleX;
      } else {
        scaleX = scaleY;
      }
      // }

      const transformMatrix = `matrix(${scaleX}, 0, 0, ${scaleY}, 0, 0)`;

      setWindowSize({
        height: Math.round(height / scaleX),
        scaleX,
        scaleY,
        transform: transformMatrix,
        width,
      });
    }

    // Добавляем слушатель изменения размера окна
    window.addEventListener("resize", handleResize);

    // Инициализируем состояние
    handleResize();

    // Удаляем слушатель при размонтировании компонента
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
export default React.memo(Game);
