import ScarecrowDamage from "./actionType/ScarecrowDamage/ScarecrowDamage";

import React from "react";
import ScarecrowTime from "./actionType/ScarecrowTime/ScarecrowTime";
import { ACTION_TYPES_ID } from "../../../services/base/constants";

function Action({ action }: any) {
  return (
    <>
      <div className={`${action.className}`}>
        {action.id === ACTION_TYPES_ID.ScarecrowAttack && (
          <ScarecrowDamage action={action} />
        )}
        {action.id === ACTION_TYPES_ID.ScarecrowTime && (
          <ScarecrowTime action={action} />
        )}
      </div>
    </>
  );
}

export default Action;
