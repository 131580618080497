import styles from "./DialogTrade.module.scss";
import { useEffect, useState } from "react";
import { getTradeList } from "../../../../../core/api/items/items.api";
import { IRequestResponse } from "../../../../../core/api/common";
import { DialogTradeBuyCardSelectList } from "./TradeComponents";
import { useSelector } from "react-redux";

function DialogTradeSell() {
  const [userItemsList, changeUserItemsList]: any = useState([]);
  const [isLoading, changeLoading]: any = useState(true);
  const { selectedItem } = useSelector((store: any) => store.items);

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    if (selectedItem === null) {
      changeLoading(true);

      setTimeout(() => {
        loadItems();
      }, 400);
    }
  }, [selectedItem]);

  function loadItems() {
    changeLoading(true);
    getTradeList("user").then((iResp: IRequestResponse) => {
      if (iResp.hasError) return;
      const list = iResp.getData();
      changeUserItemsList(list);
      changeLoading(false);
    });
  }

  return (
    <>
      <div className={styles.DialogTradeTop}>
        <DialogTradeBuyCardSelectList
          items={userItemsList}
          type={"trade.my"}
          withCountSelectedItem={true}
        />
      </div>
    </>
  );
}

export default DialogTradeSell;
