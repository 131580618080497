import { createSlice } from "@reduxjs/toolkit";

export const Mobs = createSlice({
  initialState: {
    bossData: {},
    mobData: {},
  },
  name: "Mobs",
  reducers: {
    setBossData: (state, action) => {
      state.bossData = action.payload;
    },
    setMobsData: (state, action) => {
      state.mobData = action.payload;
    },
  },
});

export const { setBossData, setMobsData } = Mobs.actions;
export default Mobs.reducer;
