export const AVAILABLE_STATES: any = {
  0: "stay",
  1: "attack",
  2: "die",
  3: "skill",
};

export const AVAILABLE_TYPES: any = {
  0: "mob",
  1: "boss",
};

export function getFullDataMobFromShort(mob: string) {
  if (!mob) return null;
  const [id, hp, maxhp, state, uniqId, type] = mob.split("|");
  let hideHp = false;
  if (hp === "null" || maxhp === "null") hideHp = true;

  return {
    hp: hideHp ? null : Number(hp),
    id,
    maxhp: hideHp ? null : Number(maxhp),
    state: AVAILABLE_STATES[state],
    type: AVAILABLE_TYPES[type],
    uniqId,
  };
}
