import { getImagePath } from "../../scss/styles";

// import { useState, useEffect, useCallback } from 'react';
const cacheOfJson: any = {};

export function random(min: number, max: number, isInteger = true) {
  const rndValue = Math.random() * (max - min) + min;
  return isInteger ? Math.floor(rndValue) : rndValue;
}

export function setToCache(key: string, data: any) {
  cacheOfJson[key] = data;
}

export function getFromCache(key: string) {
  if (!cacheOfJson[key]) return null;

  return cacheOfJson[key];
}

export async function loadJson(path: any, onItemLoad?: any) {
  if (typeof path === "string") {
    if (cacheOfJson[path]) {
      return new Promise(resolve => {
        if (onItemLoad && typeof onItemLoad === "function") onItemLoad();
        resolve(cacheOfJson[path]);
      });
    }
    const data = await import(`../../json/${path}`);
    const resolveData = data.default;
    cacheOfJson[path] = resolveData;
    if (onItemLoad && typeof onItemLoad === "function") onItemLoad();
    return resolveData;
  }
  return Promise.all(
    path.map((url: string) => {
      return loadJson(url, onItemLoad);
    }),
  );
}

export const preloadImages = (imageUrls: string[], onItemLoad: any) => {
  return Promise.all(
    imageUrls.map(imageLink => {
      const url = getImagePath(imageLink.split("/images/")[1]);
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          onItemLoad();
          resolve(url); // Успешная загрузка
        };

        img.onerror = () => {
          console.error(`Ошибка загрузки изображения: ${url}`);
          resolve(url);
        };
      });
    }),
  );
};

// interface ResourceStatus {
//   url: string;
//   status: 'loading' | 'loaded' | 'error';
//   attempts: number;
//   errorMessage?: string;
// }

// type CacheResourcesResult = {
//   status: ResourceStatus[];
//   isCompleted: boolean;
// };

// export const useCacheResources = (urls: string[]): CacheResourcesResult => {
//   const [status, setStatus] = useState<ResourceStatus[]>(() =>
//     urls.map((url) => ({
//       url,
//       status: 'loading',
//       attempts: 0,
//     }))
//   );

//   const cacheResource = useCallback(async (url: string, index: number) => {
//     let attempts = 0;
//     while (attempts < 3) {
//       try {
//         attempts++;
//         // Предполагаемая функция для загрузки ресурса (изображение или json)
//         const response = await fetch(url);

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         if (url.endsWith('.json')) {
//           await response.json(); // Проверка на валидность JSON
//         } else {
//           const blob = await response.blob(); // Для изображений и других файлов
//           const img = new Image();
//           img.src = URL.createObjectURL(blob); // Генерация URL объекта для изображения
//         }

//         setStatus((prevStatus) =>
//           prevStatus.map((s, i) =>
//             i === index
//               ? { ...s, status: 'loaded', attempts }
//               : s
//           )
//         );
//         break;
//       } catch (error: any) {
//         if (attempts >= 3) {
//           setStatus((prevStatus) =>
//             prevStatus.map((s, i) =>
//               i === index
//                 ? {
//                     ...s,
//                     status: 'error',
//                     errorMessage: `Failed to load: ${error.message}`,
//                     attempts,
//                   }
//                 : s
//             )
//           );
//         } else {
//           setStatus((prevStatus) =>
//             prevStatus.map((s, i) =>
//               i === index
//                 ? { ...s, status: 'loading', attempts }
//                 : s
//             )
//           );
//         }
//       }
//     }
//   }, []);

//   useEffect(() => {
//     status.forEach((s, index) => {
//       if (s.status === 'loading' && s.attempts < 3) {
//         cacheResource(s.url, index);
//       }
//     });
//   }, [status, cacheResource]);

//   const isCompleted = status.every((s) => s.status !== 'loading');

//   return { status, isCompleted };
// };
