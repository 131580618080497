import { request } from "../common";
import { API_DIALOG_STATE } from "../constants";

export const customUserApi = {
  getDialogState: async (params: any) => {
    return await request({
      method: "GET",
      params,
      url: `${API_DIALOG_STATE}`,
      withCredentials: true,
    });
  },
};

export const { getDialogState } = customUserApi;
