import React, { useEffect, useState } from "react";
import styles from "./CustomRange.module.scss";
import { TG_ICON } from "../../../../services/base/constants";

interface CustomRangeProps {
  min: number;
  max: number;
  title?: string;
  onChange: any;
  step?: number;
  isPrice?: boolean;
  initialValue?: number;
}
const CustomRange: React.FC<CustomRangeProps> = ({
  min,
  max,
  title,
  onChange,
  step = 1,
  initialValue = min,
  isPrice = false,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  const [rangeBackground, setRangeBackground] = useState<string>("");

  // Обновление фона ползунка
  useEffect(() => {
    updateRangeBackground(value);
    if (onChange) onChange(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
  };

  // Функция для обновления градиента фона
  const updateRangeBackground = (value: number) => {
    const percentage = ((value - min) / (max - min)) * 100;
    const newBackground = `linear-gradient(90deg, #C49345 ${percentage}%, transparent ${percentage}%)`;
    setRangeBackground(newBackground);
  };

  return (
    <div className={styles.customRangeContainer}>
      <div className={styles.rangeTitle}>{title}</div>
      <div className={`w-100 ${styles.rangeFieldContent}`}>
        {isPrice && (
          <div
            className={styles.tgIcon}
            dangerouslySetInnerHTML={{ __html: TG_ICON }}
          ></div>
        )}
        <input
          className={styles.numberInput}
          max={max}
          min={min}
          onChange={handleInputChange}
          type="number"
          value={value}
        />
      </div>
      <input
        className={styles.rangeInput}
        max={max}
        min={min}
        onChange={handleRangeChange}
        step={step}
        style={{ background: rangeBackground }}
        type="range"
        value={value}
      />
    </div>
  );
};

export default CustomRange;
