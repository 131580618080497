import { createSlice } from "@reduxjs/toolkit";

export const Items = createSlice({
  initialState: {
    data: {},
    selectedItem: null,
  },
  name: "Items",
  reducers: {
    selectItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setItemsData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { selectItem, setItemsData } = Items.actions;
export default Items.reducer;
