import { useEffect, useState } from "react";
import { random } from "../../../../services/base/list";
import styles from "./HealEffect.module.scss";

export default function HealEffect(props: any) {
  const [elements, changeElements] = useState([]);

  useEffect(() => {
    const emptyElements: any = new Array(random(20, 36)).fill(0);
    let prevValueX = 0;

    changeElements(
      emptyElements.map((item: any) => {
        const posX = prevValueX + 95 / emptyElements.length;
        const posY = random(3, 160);

        prevValueX = posX;

        return {
          fontSize: `${random(0, 2)}.${random(0, 99)}em`,
          hasBar: random(0, 3) === 1,
          isActive: random(0, 2) === 1,
          x: posX + "%",
          y: posY + "%",
        };
      }),
    );
  }, []);

  return (
    <div className={styles.HealEffect}>
      <div className={styles.HealEffectStars}>
        {elements.map((el: any, idx: number) => {
          let classes = "";

          if (el.isActive) {
            classes += styles.healActive + " ";
          } else if (el.hasBar) {
            classes += styles.healBarEffect;
          }

          return (
            <span
              key={idx}
              className={classes}
              style={{
                fontSize: `${el.fontSize}`,
                left: `${el.x}`,
                top: `${el.y}`,
              }}
            >
              +
            </span>
          );
        })}
      </div>
      <div className={styles.HealEffectBottom}></div>
    </div>
  );
}
