import { useSelector } from "react-redux";
import ItemsPager from "../../ItemsPager/ItemsPager";
import styles from "./DialogBank.module.scss";
import { useEffect, useState } from "react";
import { putOrGetFromBank } from "../../../../../core/api/items/items.api";
import { IRequestResponse } from "../../../../../core/api/common";
import UnlockItemsModal from "../../UnlockItemsModal/UnlockItemsModal";
import LoaderBtn from "../../Loader/LoaderBtn";

function DialogBankComponent({ type }: any) {
  const [unlockItemsModal, changeUnlockItemsModal]: any = useState(false);
  const [isLoading, changeIsLoading]: any = useState(false);

  const { params } = useSelector((store: any) => store.user);
  const [selectedItemsCount, changeSelectedItemsCount]: any = useState(0);
  const selectedItems: any = useState({}); // {1{value}}

  function getActiveSelectedItems() {
    return Object.keys(selectedItems[0])
      .filter(itemKey => {
        return selectedItems[0][itemKey] && selectedItems[0][itemKey].isActive;
      })
      .map(itemKey => {
        return `${itemKey}:1`;
      });
  }

  useEffect(() => {
    changeSelectedItemsCount(getActiveSelectedItems().length);
  }, [selectedItems[0]]);

  return (
    <div className={styles.BankComponent}>
      <div className={styles.BankTopSide}>
        <div className={styles.ItemsPager}>
          {type === "bank.put" && (
            <ItemsPager
              isSelected={true}
              items={params.items}
              maxCount={120}
              onClickUnlock={() => {
                changeUnlockItemsModal(true);
              }}
              pageCount={120}
              selectedItems={selectedItems}
              showPagination={false}
              type={type}
            />
          )}

          {type === "bank.get" && (
            <ItemsPager
              isSelected={true}
              items={params.bank}
              maxCount={160}
              onClickUnlock={() => {
                changeUnlockItemsModal(true);
              }}
              pageCount={160}
              selectedItems={selectedItems}
              showPagination={false}
              type={type}
            />
          )}
        </div>
      </div>

      <div className={styles.BankBottomSide}>
        <div
          className={`${styles.BankBtn}`}
          onClick={() => {
            if (selectedItemsCount === 0 || isLoading) return;
            changeIsLoading(true);
            if (type === "bank.put") {
              putOrGetFromBank({
                cellIds: getActiveSelectedItems(),
                type: "put",
              }).then((iResp: IRequestResponse) => {
                selectedItems[1]({});
                changeIsLoading(false);
              });
            } else {
              putOrGetFromBank({
                cellIds: getActiveSelectedItems(),
                type: "get",
              }).then((iResp: IRequestResponse) => {
                selectedItems[1]({});
                changeIsLoading(false);
              });
            }
          }}
        >
          {type === "bank.put" ? "Положить" : "Забрать"}
          {selectedItemsCount ? ` (${selectedItemsCount})` : null}
          <LoaderBtn isActive={isLoading} />
        </div>
      </div>
      {unlockItemsModal ? (
        <UnlockItemsModal
          onClose={() => {
            changeUnlockItemsModal(false);
          }}
          type={type === "bank.put" ? "backpack" : "bank"}
        />
      ) : null}
    </div>
  );
}

export default DialogBankComponent;
