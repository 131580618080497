import { useEffect, useRef, useState } from "react";
import styles from "./QuickInfo.module.scss";
import { socketConnection } from "../../../../core/api/common";

function QuickInfo() {
  const [messages, updateMessageList]: any = useState([]);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    let timeout: any = null;
    socket.on("quick message", (info: any) => {
      if (info.hasError) return;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      const { msg } = info.getData();
      updateMessageList((old: any) => {
        return [...old, { msg, time: +new Date() }];
      });

      timeout = setTimeout(() => {
        updateMessageList([]);
      }, 2000);
    });
    return () => {
      socket.off("quick message");
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={styles.QuickInfoContainer}>
      {messages.map(({ msg, time }: any) => {
        return (
          <div key={time} className={styles.QuickInfo}>
            {msg}
          </div>
        );
      })}
    </div>
  );
}

export default QuickInfo;
