import {
  faAnglesDown,
  faAnglesRight,
  faAnglesUp,
  faArrowDown,
  faClose,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./ExchangeItemModal.module.scss";
import { useSelector } from "react-redux";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import ItemsPager, { ItemCell } from "../ItemsPager/ItemsPager";
import { parseItem } from "../../../../services/base/item";
import React from "react";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import { craftItem, exchangeItem } from "../../../../core/api/items/items.api";

function ExchangeItemModal({ item, onClose }: any) {
  if (!item) return null;
  const [selectedResource, changeSelectedResource]: any = useState(null);
  const [availableWeightOfItem, changeAvailableWeightOfItem]: any =
    useState(null);
  const [exchangeResource, changeExchangeResource]: any = useState(item);
  const { exchangeWeight, items } = useSelector(
    (store: any) => store.items,
  ).data;
  const [resources, changeResources]: any = useState([]);

  useEffect(() => {
    const [itemId, level, count] = parseItem(item);
    const weightExchangeItem = items[itemId].weight;
    const countOfMainItemByWeight: any = {};

    changeResources(
      Object.keys(items)
        .filter((itemKey: any) => {
          const it = items[itemKey];
          return it.type === "resource" && it.isExchange && itemKey !== itemId;
        })
        .map((itemKey: any) => {
          const { weight } = items[itemKey];
          const resOfExchange =
            exchangeWeight[`${weightExchangeItem}-${weight}`];

          const [getCount, giveCount] = resOfExchange.split("|");

          countOfMainItemByWeight[itemKey] = getCount;

          return `${itemKey}|0|${giveCount}|0`;
        }),
    );

    changeAvailableWeightOfItem(countOfMainItemByWeight);
  }, []);

  return (
    <div className={styles.ExchangeContainer}>
      <ActionModalWithButtons
        buttons={[
          {
            action: () => {
              const [getItem] = parseItem(exchangeResource);
              const [giveItem] = parseItem(selectedResource);

              exchangeItem({ getItem, giveItem }).then(
                (resp: IRequestResponse) => {
                  if (resp.hasError) return;
                },
              );
            },
            isDisabled: !selectedResource,
            title: "Обменять",
          },
        ]}
        onClose={() => {
          onClose();
        }}
        toTop={true}
        withBkg={true}
      >
        <div className={`ItemsList ${styles.ExchangeItemChild}`}>
          <h2 className="mt-0 white-text text-center w-100 mb-8">
            Ресурс для обмена
          </h2>

          <div className={`${styles.ExchangeModItems} `}>
            {[3, 4, 4, 3].map((rowSize, idxRow) => {
              const startIdx = idxRow === 0 ? 0 : [3, 7, 11][idxRow - 1];
              const endIdx = startIdx + rowSize;
              return (
                <div
                  key={idxRow}
                  className={`${styles.ExchangeModItemsItemRow}`}
                >
                  {resources
                    .slice(startIdx, endIdx)
                    .map((resource: any, idxCell: any) => {
                      return (
                        <div
                          key={`${idxRow}-${idxCell}`}
                          className={`Item mb-2 ${
                            styles.ExchangeModItemsItem
                          } ${
                            resource === selectedResource
                              ? styles.SelectedResouce
                              : ""
                          }`}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            changeSelectedResource(resource);
                            const [itemId, level, count] = parseItem(item);
                            const [resItemId] = parseItem(resource);

                            changeExchangeResource(
                              `${itemId}|${level}|${availableWeightOfItem[resItemId]}|0`,
                            );
                          }}
                        >
                          <ItemCell
                            isDisabled={
                              selectedResource !== null &&
                              resource !== selectedResource
                            }
                            item={resource}
                            withLevel={true}
                          />
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
          <div className={`${styles.ExchangeArrow}`}>
            <FontAwesomeIcon icon={faAnglesDown} size="1x" />
          </div>
          <div className={`${styles.ExchangeItemItems}`}>
            <div className={`${styles.ExchangeItemItem}`}>
              <div
                className={`${styles.ExchangeItemElement} Item DisableHover mb-4`}
              >
                <ItemCell item={exchangeResource} withLevel={true} />
              </div>
            </div>
          </div>
        </div>
      </ActionModalWithButtons>
    </div>
  );
}

export default React.memo(ExchangeItemModal);
