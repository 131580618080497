import { useEffect, useState } from "react";

import FullModal from "../../FullModal/FullModal";
import ItemsPager from "../../ItemsPager/ItemsPager";
import { useSelector } from "react-redux";
import styles from "./DialogRobber.module.scss";

function DialogRobber({ onClose, type }: any) {
  const [title, changeTitle]: any = useState("Выбор нужного ресурса");
  // const [activeType, changeActiveType] = useState(icons[0]?.type);
  const { items } = useSelector((store: any) => store.items).data;

  const [resources, changeResources]: any = useState([]);

  useEffect(() => {
    changeResources(
      Object.keys(items)
        .filter((itemKey: any) => {
          const item = items[itemKey];
          return item.type === "resource" && item.isExchange;
        })
        .map((itemKey: any) => `${itemKey}|0|1|0`),
    );
  }, []);

  return (
    <>
      <FullModal
        buttons={{
          active: null,
          list: [],
          onClick: null,
        }}
        hideBottom={true}
        isDisabledGlobalPadding={true}
        isDisabledPadding={true}
        onClose={() => {
          onClose();
        }}
        title={title}
      >
        <div className={styles.RobberContainer}>
          <ItemsPager
            items={resources}
            maxCount={resources.length}
            pageCount={resources.length}
            showCount={false}
            showLock={false}
            showPagination={false}
            type={"exchange"}
          />
        </div>
      </FullModal>
    </>
  );
}

export default DialogRobber;
