import { useEffect, useState, ReactNode } from "react";
import ReactDOM from "react-dom";
import styles from "./FullModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { setIsModalOpenedState } from "../../../../services/store/other/other.store";
import store from "../../../../services/store.service";

interface ModalChatProps {
  title: string;
  children: ReactNode;
  buttons?: any;
  isDisabledPadding?: boolean;
  hideBottom?: boolean;
  isDisabledGlobalPadding?: boolean;
  buttonClick?: any;
  onClose: () => void;
}

function FullModal({
  buttons,
  children,
  hideBottom = false,
  isDisabledGlobalPadding = false,
  isDisabledPadding = false,
  onClose,
  title,
}: ModalChatProps) {
  const el: any = document.getElementById("portal-root");
  const [expandedItems, changeExpandedItems]: any = useState([]);
  const [isMoreSelected, isMoreSelectedChange]: any = useState(false);
  useEffect(() => {
    store.dispatch(setIsModalOpenedState(true));

    return () => {
      store.dispatch(setIsModalOpenedState(false));
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={`${styles.FullModalContainer} ${
        isDisabledGlobalPadding ? styles.FullModalContainerDisabledPadding : ""
      }`}
    >
      <div className={styles.FullModalHeader}>
        <div
          className={`${styles.FullModalBack} ${
            isDisabledGlobalPadding ? "ml-2" : ""
          }`}
          onClick={() => {
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <h3>{title}</h3>
      </div>
      <div
        className={`${styles.FullModalContent} bkg-modal ${
          isDisabledPadding ? styles.FullModalContentDisabledPadding : ""
        } ${hideBottom ? styles.FullModalContentHideBottom : ""}`}
      >
        {children}
      </div>
      {expandedItems && expandedItems.length ? (
        <div className={styles.FullModalExpandedMenu}>
          {expandedItems.map((item: any, idx: number) => {
            return (
              <div
                key={idx}
                className={styles.FullModalExpandedItem}
                onClick={() => {
                  buttons.onClick(item.type);
                  changeExpandedItems([]);
                  isMoreSelectedChange(true);
                }}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      ) : null}

      {buttons && !hideBottom ? (
        <div className={styles.FullModalButtons}>
          {buttons.list.map((btn: any, idx: number) => {
            return (
              <div
                key={`${btn.type} ${idx}`}
                className={`${styles.FullModalButton} ${
                  buttons.active === btn.type ||
                  (btn.isExpand && isMoreSelected)
                    ? styles.FullModalButtonActive
                    : ""
                }`}
                onClick={() => {
                  if (btn.isExpand) {
                    if (expandedItems.length) {
                      changeExpandedItems([]);
                      return;
                    }
                    changeExpandedItems(btn.children);
                    return;
                  }
                  changeExpandedItems([]);
                  isMoreSelectedChange(false);
                  buttons.onClick(btn.type);
                }}
              >
                {btn.icon}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>,
    el,
  );
}

export default FullModal;
