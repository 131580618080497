import { useEffect, useRef, useState } from "react";

import React from "react";
import { IRequestResponse, socketConnection } from "../../../core/api/common";
import { ACTION_TYPES_ID } from "../../../services/base/constants";
import store from "../../../services/store.service";
import Action from "./Action";
import {
  doResponseScarecrow,
  getActionInfoScarecrowTime,
} from "./actionType/ScarecrowTime/Methods";

function Actions({ actions }: any) {
  const [socket]: any = useState(socketConnection());
  const [locActions, changeLocActions]: any = useState([]);

  useEffect(() => {
    changeLocActions(getActions());

    socket.on("do action response", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }
      const action = resp.getData();

      switch (action.actionId) {
        case ACTION_TYPES_ID.ScarecrowAttack: {
          doResponseScarecrow(changeLocActions, action);
        }
      }
    });

    socket.on("get action info", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }
      const action = resp.getData();
      switch (action.id) {
        case ACTION_TYPES_ID.ScarecrowTime: {
          getActionInfoScarecrowTime(changeLocActions, action);
        }
      }
    });

    return () => {
      socket.off("do action response");
      socket.off("get action info");
    };
  }, []);

  const getActions = () => {
    const locId = store.getState().location.id;
    return (actions || []).map((action: any, idx: number) => {
      const uniqId = `${locId}|${action.id}|${idx}`;
      let needGetInfo = false;
      if (action.id === ACTION_TYPES_ID.ScarecrowAttack) needGetInfo = true;

      return {
        ...action,
        className: `action-level-${locId}-action-${idx}`,
        needGetInfo,
        results: [],
        targetId: idx,
        uniqId,
      };
    });
  };

  return (
    <>
      {(locActions || []).map((action: any) => {
        if (action.needGetInfo) return null;

        return <Action key={action.uniqId} action={action} />;
      })}
    </>
  );
}

export default Actions;
