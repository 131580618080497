export function getCssImagePath(relativePath: string) {
  return `url(${getImagePath(relativePath)})`;
}

export function getImagePath(relativePath: string) {
  const isProd = process.env.REACT_APP_IS_PROD === "true";
  const imagePath = process.env.REACT_APP_IMAGE_PATH || "";
  const version = process.env.REACT_APP_IMAGE_VERSION || "";
  const url = `${imagePath}${relativePath}?version=${version}`;
  try {
    return isProd
      ? `${url}`
      : `${require(`${
          process.env.REACT_APP_IMAGE_PATH || ""
        }${relativePath}?version=${
          process.env.REACT_APP_IMAGE_VERSION || ""
        }`)}`;
  } catch (error) {
    console.error("Error loading image:", error);
    return "";
  }
}
