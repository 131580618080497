import { useEffect, useState } from "react";

import FullModal from "../../FullModal/FullModal";
import DialogBankComponent from "./Bank";

function DialogBank({ onClose, type }: any) {
  const SHOP_TYPES: any = {
    "bank.get": { icons: [], title: "Банк" },
    "bank.put": { icons: [], title: "Инвентарь" },
  };

  const [activeType, changeActiveType] = useState(
    SHOP_TYPES[type]?.icons[0]?.type,
  );

  return (
    <>
      <FullModal
        buttons={{
          active: activeType,
          list: SHOP_TYPES[type].icons,
          onClick: (type: string) => {
            changeActiveType(type);
          },
        }}
        hideBottom={true}
        isDisabledGlobalPadding={true}
        isDisabledPadding={true}
        onClose={() => {
          onClose();
        }}
        title={SHOP_TYPES[type].title}
      >
        <DialogBankComponent type={type} />
      </FullModal>
    </>
  );
}

export default DialogBank;
