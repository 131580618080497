import { combineReducers, configureStore } from "@reduxjs/toolkit";

import backpack from "./store/backpack/backpack.store";
import location from "./store/location/location.store";
import mobs from "./store/mobs/mobs.store";
import npc from "./store/npc/npc.store";
import items from "./store/items/items.store";

import user from "./store/user/user.store";

import other from "./store/other/other.store";
import skill from "./store/skill/skill.store";
import modal from "./store/modal/modal.store";

import dialog from "./store/dialog/dialog.store";

export default configureStore({
  reducer: {
    backpack: backpack,
    dialog,
    items,
    location: location,
    mobs: mobs,
    modal: modal,
    npc: npc,
    other: other,
    skill: skill,
    user: user,
  },
});
