import { useState } from "react";
import styles from "./ItemInfo.module.scss";

import React from "react";

import TgCoin from "../TgCoin/TgCoin";
import CustomRange from "../CustomRange/CustomRange";
import { parseItem } from "../../../../services/base/item";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

function AdditionalyMenu({ rangeStates, selectedItem }: any) {
  const TRADE = (
    <div className={`${styles.InfoPrice}`}>
      <div className={styles.ItemCount}>Стоимость 1 шт</div>
      <div>
        <TgCoin value={selectedItem.price} />
      </div>
    </div>
  );

  switch (selectedItem.type) {
    case "trade.my": {
      return TRADE;
    }
    case "trade": {
      const [itemId, level, count] = parseItem(selectedItem.item);

      return (
        <>
          {Number(count) === 1 ? (
            <div
              className={`d-flex justify-between white-text ${styles.ItemCount} mb-4`}
            >
              <div>Количество:</div>
              <div>1</div>
            </div>
          ) : (
            <div className="mb-2">
              <CustomRange
                max={Number(count)}
                min={1}
                onChange={(value: any) => {
                  rangeStates.count[1](value);
                }}
                title="Количество"
              />
            </div>
          )}

          {TRADE}
          <div className={`${styles.InfoPrice} mt-4`}>
            <div className={styles.ItemCount}>Итого:</div>
            <div>
              <TgCoin
                value={selectedItem.price * Number(rangeStates.count[0])}
              />
            </div>
          </div>
        </>
      );
    }
    case "sell": {
      const [itemId, level, count] = parseItem(selectedItem.item);
      const [showPopover, setShowPopover] = useState(false);

      const handleTogglePopover = () => {
        setShowPopover(!showPopover);
      };

      return (
        <>
          <div>
            <div className="mb-4">
              <CustomRange
                isPrice={true}
                max={9999}
                min={0.0001}
                onChange={(value: any) => {
                  rangeStates.price[1](value);
                }}
                step={0.0001}
                title="Цена за 1 ед"
              />
            </div>
            <div>
              {Number(count) === 1 ? (
                <div
                  className={`d-flex justify-between white-text ${styles.ItemCount}`}
                >
                  <div>Количество:</div>
                  <div>1</div>
                </div>
              ) : (
                <CustomRange
                  max={Number(count)}
                  min={1}
                  onChange={(value: any) => {
                    rangeStates.count[1](value);
                  }}
                  title="Количество"
                />
              )}

              <div className="mt-4">
                <div
                  className={`d-flex justify-between white-text ${styles.ItemCount} ${styles.totalInfo}`}
                >
                  <div>Итого:</div>
                  <div className="d-flex align-items-center">
                    <TgCoin
                      value={rangeStates.price[0] * rangeStates.count[0]}
                    />
                    <FontAwesomeIcon
                      className={`ml-2 ${styles.itemPriceInfo}`}
                      icon={faCircleExclamation}
                      onClick={() => {
                        handleTogglePopover();
                      }}
                    />
                    {showPopover && (
                      <div className={`popover ${styles.itemInfoPopover}`}>
                        <div className="popover-content">
                          <p>
                            <b>20%</b> с каждой продажи перенаправляется в общий
                            фонд.
                          </p>
                          <div className="popover-arrow right"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    case "bank.get":
    case "bank.put": {
      const [itemId, level, count] = parseItem(selectedItem.item);
      return (
        <div className={`${styles.InfoPrice}`}>
          <CustomRange
            max={Number(count)}
            min={1}
            onChange={(value: any) => {
              rangeStates.count[1](value);
            }}
            title="Количество"
          />
        </div>
      );
    }
  }
  return null;
}

export default React.memo(AdditionalyMenu);
