import { createSlice } from "@reduxjs/toolkit";

export const Npc = createSlice({
  initialState: {
    data: {},
  },
  name: "Npc",
  reducers: {
    setNpcData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setNpcData } = Npc.actions;
export default Npc.reducer;
