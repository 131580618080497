import { useEffect, useRef, useState } from "react";
import styles from "./ScarecrowDamage.module.scss";
import React from "react";
import AnimationAttack from "../../../animationAttack/AnimationAttack";
import ExpAnimate from "../../../expAnimate/expAnimate";
import MobHealthBar from "../../../base/MobHealthBar/MobHealthBar";
import { socketConnection } from "../../../../../core/api/common";
import { ACTION_TYPES_ID } from "../../../../../services/base/constants";

function ScarecrowDamage({ action }: any) {
  const [isKilled, changeKilledStatue]: any = useState(false);
  const [hideAction, changeHideAction]: any = useState(false);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    if (!action.mob) {
      changeHideAction(true);
    }
  }, []);

  useEffect(() => {
    if (!action.mob) changeKilledStatue(true);
  }, [action.mob]);

  return (
    <>
      {hideAction ? null : (
        <div
          className={styles.ScarecrowDamageCover}
          onClick={() => {
            socket.send("do action", {
              id: action.id,
              targetId: action.targetId,
              uniqId: action.uniqId,
            });

            socket.send("get action info", {
              id: ACTION_TYPES_ID.ScarecrowTime,
            });
          }}
        >
          {action.results[action.results.length - 1]?.isKilled && (
            <ExpAnimate
              value={action.results[action.results.length - 1]?.exp}
            />
          )}

          <div
            className={`
              ${styles.ScarecrowDamage} 
              ${styles[`ScarecrowSubType-${action.sub_id}`]}
              ${isKilled ? styles.ScarecrowKilled : ""}
            `}
          >
            <div>
              {action?.mob?.hp ? (
                <div key={action?.mob?.hp}>
                  <MobHealthBar
                    percent={(action?.mob?.hp / action?.mob?.maxhp) * 100}
                  ></MobHealthBar>
                </div>
              ) : null}

              <div className={styles.ScarecrowDamageInfo}>
                {(action.results || []).map(
                  ({ type, value }: any, key: number) => {
                    return (
                      <AnimationAttack key={key} type={type} value={value} />
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ScarecrowDamage;
