import { useSelector } from "react-redux";
import DialogTradeAction from "../DialogTrade/DialogTrade";
import DialogBank from "../DialogBank/DialogBank";
import DialogRaks from "../DialogRaks/DialogRaks";
import DialogLeksli from "../DialogLeksli/DialogLeksli";
import DialogRobber from "../DialogRobber/DialogRobber";

function DialogComponent({ onClose, type }: any) {
  return (
    <div>
      {(type === "trade.buy" ||
        type === "trade.sell" ||
        type === "trade.uniq") && (
        <>
          <DialogTradeAction
            onClose={() => {
              onClose();
            }}
            type={type}
          />
        </>
      )}
      {(type === "bank.put" || type === "bank.get") && (
        <>
          <DialogBank
            onClose={() => {
              onClose();
            }}
            type={type}
          />
        </>
      )}
      {type === "raks" && (
        <>
          <DialogRaks
            onClose={() => {
              onClose();
            }}
            type={type}
          />
        </>
      )}
      {type === "leksli" && (
        <>
          <DialogLeksli
            onClose={() => {
              onClose();
            }}
            type={type}
          />
        </>
      )}
      {type === "robber" && (
        <>
          <DialogRobber
            onClose={() => {
              onClose();
            }}
            type={type}
          />
        </>
      )}
    </div>
  );
}

export default DialogComponent;
