import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  paneState: any;
  mobsKilled: any;
}

export const User = createSlice({
  initialState: {
    acts: {},
    config: {},
    params: {},
  },
  name: "user",
  reducers: {
    setUserActions: (state, action) => {
      state.acts = action.payload;
    },
    setUserConfig: (state, action) => {
      state.config = action.payload;
    },
    setUserMainParams: (state, action) => {
      state.params = action.payload;
    },
  },
});

export const { setUserActions, setUserConfig, setUserMainParams } =
  User.actions;
export default User.reducer;
