import { useEffect, useRef, useState } from "react";
import styles from "./Chat.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "../../../../services/store.service";

import {
  faClose,
  faPaperPlane,
  faCalendarDays,
  faEnvelope,
  faUsers,
  faBuildingShield,
  faComments,
  faDungeon,
  faPlus,
  faArrowUp,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import { toggleChat } from "../../../../services/store/other/other.store";
import FullModal from "../FullModal/FullModal";

const chatTypes: any = [
  { icon: <FontAwesomeIcon icon={faComments} />, isActive: true, type: "all" },
  { icon: <FontAwesomeIcon icon={faDungeon} />, type: "dungeon" },
  { icon: <FontAwesomeIcon icon={faBuildingShield} />, type: "guild" },
  { icon: <FontAwesomeIcon icon={faUsers} />, type: "group" },
  { icon: <FontAwesomeIcon icon={faEnvelope} />, type: "personal" },
  { icon: <FontAwesomeIcon icon={faCalendarDays} />, type: "events" },
];

function MessageItem({ message }: any) {
  const [socket]: any = useState(socketConnection());

  if (!message.user) return null;
  return (
    <div
      className={`${styles.ChatInfoMessagesItem} ${
        message.type ? styles[`MessageItemType-${message.type}`] : ""
      }`}
    >
      <span className={styles.ChatInfoMessagesItemName}>
        [{message.user.username}]:{" "}
      </span>
      {message.group ? (
        <span>
          Приглашает вас в группу{" "}
          <span
            onClick={() => {
              socket.emit("join group", message.group.groupId);
            }}
          >
            <FontAwesomeIcon
              className={styles.ChatAddToGroupBtn}
              icon={faPlus}
            />
          </span>
        </span>
      ) : (
        <span>{message.message}</span>
      )}
    </div>
  );
}

interface HorizontalLoaderProps {
  loading: boolean;
}

const HorizontalLoader: React.FC<HorizontalLoaderProps> = ({ loading }) => {
  return (
    <div className={`horizontal-loader ${loading ? "show" : ""}`}>
      <div className="loader"></div>
    </div>
  );
};

function Chat({ onClose }: any) {
  const buttons: any = chatTypes.slice();
  const [chatType, setChatType]: any = useState(chatTypes[0].type);
  const [messages, setMessages]: any = useState({});
  const [messageCount, changeMessageCount]: any = useState({});
  const [isLoading, changeLoading]: any = useState(true);
  const MSG_LIST = [
    {
      author: "zidein",
      isMyMessage: true,
      messages: [
        { message: "Hello there!", time: "10:00" },
        { message: "Hello world!", time: "10:01" },
        { message: "Hello world!", time: "10:02" },
      ],
    },
    {
      author: "vivi",
      isMyMessage: false,
      messages: [
        { message: "Hello there!", time: "10:00" },
        { message: "Hello world!", time: "10:01" },
        { message: "Hello world!", time: "10:02" },
      ],
    },
    {
      author: "zidein",
      isMyMessage: true,
      messages: [
        { message: "Hello there!", time: "10:00" },
        { message: "Hello world!", time: "10:01" },
        { message: "Hello world!", time: "10:02" },
      ],
    },
    {
      author: "vivi",
      isMyMessage: false,
      messages: [{ message: "Hello there!", time: "10:00" }],
    },
    {
      author: "vivi7",
      isMyMessage: false,
      messages: [
        { message: "Hello there!", time: "10:00" },
        { message: "Hello there!", time: "10:00" },
      ],
    },
    {
      author: "vivi99",
      isMyMessage: false,
      messages: [
        { message: "Hello there!", time: "10:00" },
        { message: "Hello there!", time: "10:00" },
        {
          message:
            "Hello there! Hello there!Hello there! Hello there!Hello there! Hello there!Hello there! Hello there!Hello there! Hello there!",
          time: "10:00",
        },
        { message: "Hello there!", time: "10:00" },
        { message: "Hello there!", time: "10:00" },
        { message: "Hello there!", time: "10:00" },
        { message: "Hello there!", time: "10:00" },
      ],
    },
  ];

  const [message, changeMsg]: any = useState("");

  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    socket.emit("has new messages");

    socket.on("has new messages", (hasAnyMessage: any) => {
      if (hasAnyMessage.hasError) return;
      changeMessageCount(hasAnyMessage.getData());

      loadNewMessages(chatType);
    });

    socket.on("load messages", (msgResponse: IRequestResponse) => {
      if (msgResponse.hasError) return;
      changeLoading(false);

      const msgData = msgResponse.getData();
      const msgByType = messages[msgData.type] ? messages[msgData.type] : [];

      setMessages({
        ...messages,
        [msgData.type.type]: [...msgByType, ...msgData.messages],
      });
    });

    return () => {
      socket.off("load messages");
      socket.off("has new messages");
    };
  }, []);

  function loadNewMessages(type: string) {
    changeLoading(true);
    socket.emit("load messages", type);
  }

  return (
    <FullModal
      buttons={{
        active: chatType,
        list: buttons,
        onClick: (type: string) => {
          setChatType(type);
        },
      }}
      isDisabledGlobalPadding={true}
      isDisabledPadding={true}
      onClose={() => {
        store.dispatch(toggleChat(false));
      }}
      title="Сообщения"
    >
      <div className={styles.ChatContainer}>
        {/* <div className={styles.ChatNoMessage}>
          <div className={styles.ChatNoMessageIcon}></div>
          <h3 className={styles.ChatNoMessageText}>Нет сообщений</h3>
        </div> */}
        <div className={styles.ChatMessages}>
          {MSG_LIST.map((msg, idx) => {
            return (
              <div
                key={idx}
                className={`${styles.ChatMessageGroup} ${
                  msg.isMyMessage ? styles.ChatMessageGroupMy : ""
                }`}
              >
                <div className={styles.ChatMessageGroupName}>{msg.author}</div>
                <div className={styles.ChatMessageGroupItems}>
                  {msg.messages.map((msgItem, msgIndex, { length }) => {
                    const isLast = length - 1 === msgIndex;
                    return (
                      <div
                        key={`${idx}|${msgIndex}`}
                        className={styles.ChatMessageItem}
                      >
                        {msgItem.message}
                        {isLast && (
                          <div className={styles.ChatMessageItemDate}>
                            {msgItem.time}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.ChatSendMessage}>
          <div className={styles.ChatSendMessageField}>
            <input
              className={`input-field ${styles.ChatSendMessageFieldInput}`}
              onChange={(e: any) => {
                changeMsg(e.target.value);
              }}
              placeholder="Введите сообщение..."
            />
          </div>

          <div
            className={styles.ChatSendMessageBtn}
            onClick={() => {
              socket.send("send message", {
                message,
                type: "chat",
              });
              changeMsg("");
              socket.emit("load messages");
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} size="lg" />
          </div>
        </div>
      </div>
    </FullModal>
  );
}

export default Chat;
