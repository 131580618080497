import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./DisconnectedModal.module.scss";
import React from "react";

function DisconnectedModal({ item, onClose }: any) {
  useEffect(() => {
    //
  }, []);

  return (
    <div className={styles.ImproveItemContainer}>
      <div className={styles.ImproveItem}>
        <div
          className={"close-modal BtnWithPressed"}
          onClick={() => {
            onClose();
          }}
        ></div>
        <h2 className="my-0 white-text text-left">Соединение разорвано</h2>

        <div className={`white-text ${styles.WarnDescription} my-4`}>
          <p className={`mt-0 mb-0 ${styles.WarnDescriptionText}`}>
            Возможно, вы вошли через другое приложение или идут технические
            работы. Попробуйте войти снова.
          </p>
        </div>

        {/* <div
          className={`${styles.ImproveBtn} BtnWithPressed`}
          onClick={() => {
            // socket.send("unlock backpack cells", item);
          }}
        >
          Ок
        </div> */}
      </div>
    </div>
  );
}

export default React.memo(DisconnectedModal);
