import { createSlice } from "@reduxjs/toolkit";
interface Payload {
  time: number | null;
  type: "defence" | "fire" | "water"; // Здесь перечисляем поддерживаемые типы
}
export const Skill = createSlice({
  initialState: {
    defence: null,
    fire: null,
    water: null,
  },
  name: "skill",
  reducers: {
    setTime: function (state: any, action: { payload: Payload }) {
      const { time, type } = action.payload;
      state[type] = time;
    },
  },
});

export const { setTime } = Skill.actions;
export default Skill.reducer;
