import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Backpack from "../backpack/Backpack";
import Mobs from "../mobs/Mobs";
import styles from "./Location.module.scss";
import store from "../../../services/store.service";
import {
  changeLocation,
  isVillage,
} from "../../../services/store/location/location.store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownLong,
  faLeftLong,
  faRightLong,
  faUpLong,
} from "@fortawesome/free-solid-svg-icons";
import { IRequestResponse, socketConnection } from "../../../core/api/common";
import {
  BOSS_PREFIX,
  GROUP_ELEMENT_AT_LOCATION_ID,
  MOB_PREFIX,
} from "../../../services/base/constants";
import Npcs from "../npcs/Npcs";
import Actions from "../action/Actions";
import ItemInfo from "../base/ItemInfo/ItemInfo";

import HealEffectContainer from "../base/HealEffect/HealEffectContainer";
import LocationIcons from "../base/LocationIcons/LocationIcons";
import Skill from "../base/Skill/Skill";
import {
  setGroupEnd,
  updateAttackState,
} from "../../../services/store/other/other.store";
import TimeEnd from "../base/TimeEnd/TimeEnd";
import GroupEndModal from "../base/GroupEndModal/GroupEndModal";
import { injectStyles } from "../../../services/base/styles";
import { getCssImagePath } from "../../../scss/styles";

const arrowsStyle = `
  width: 45px;
  height: 45px;
  max-width: 60px;
  max-height: 60px;
  color: white;
  cursor: pointer;
  line-height: 45px;
  background: ${getCssImagePath("components/interface/backpack/btn-del.png")};
  background-size: 100% 100%;
  font-size: 16px;
  animation: enlarge 0.65s infinite alternate;
`;

const DIRECTION: any = {
  bottom: <FontAwesomeIcon icon={faDownLong} size="lg" />,
  left: <FontAwesomeIcon icon={faLeftLong} size="lg" />,
  right: <FontAwesomeIcon icon={faRightLong} size="lg" />,
  top: <FontAwesomeIcon icon={faUpLong} size="lg" />,
};

let injectStylesFunc: any = null;

function Location() {
  const backpack = useSelector((store: any) => store.backpack);
  const locationsData = useSelector((store: any) => store.location);
  const { isModalOpened } = useSelector((store: any) => store.other);

  const [location, setLocationData]: any = useState({ doors: [], style: {} });
  // const [injectStylesFunc, setInjectStyles]: any = useState(null);

  const [socket]: any = useState(socketConnection());
  const [isLoading, changeLoading] = useState(true);

  const { actionSeed } = useSelector((store: any) => store.other);

  useEffect(() => {
    getLocationData();

    socket.on("change location", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }

      store.dispatch(changeLocation(resp.getData()));
      getLocationData();
    });

    socket.on("group end", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }

      const groupEndData = resp.getData();

      if (groupEndData.reason === "left") {
        return;
      } else {
        store.dispatch(setGroupEnd(groupEndData));
      }
    });

    socket.on("exit group", (resp: IRequestResponse) => {
      if (resp.hasError) {
        return;
      }

      if (resp.getData()) {
        changeLoading(false);
      }
    });

    return () => {
      socket.off("change location");
      socket.off("exit group");
      socket.off("group end");
      if (injectStylesFunc) injectStylesFunc();
    };
  }, []);

  function getLocationData() {
    if (injectStylesFunc) {
      injectStylesFunc();
      injectStylesFunc = null;
    }
    const locId = store.getState().location.id;
    const locData = locationsData.data[locId];

    setLocationData({
      ...locData,
    });

    const posMobPositions = (locData.mob_positions || []).map((pos: any) => {
      return `#${MOB_PREFIX}${pos.id} {left: ${pos.x}%;top: ${pos.y}%}`;
    });

    const posBossPositions = (locData.boss_positions || []).map((pos: any) => {
      return `#${BOSS_PREFIX}${pos.id} {left: ${pos.x}%;top: ${pos.y}%}`;
    });

    const posDoorsPositions = (locData.doors || []).map(
      (door: any, idx: number) => {
        return `.door-level-${locId}-door-${idx} {
                left: ${door.x + "%"};top: ${door.y + "%"};
                position: absolute;
                ${
                  door.isArrow
                    ? arrowsStyle
                    : `width: ${door.w + "%"};height: ${door.h + "%"};`
                }
                
                ${
                  door.image_id === null
                    ? ""
                    : `background: url(${require("../../../images/components/game-location/maps/locations-door.png")});`
                }
                
              }`;
      },
    );

    const posActionScss = (locData.actions || []).map(
      (action: any, idx: number) => {
        return `.action-level-${locId}-action-${idx} {
              left: ${action.x + "%"};top: ${action.y + "%"};
              position: absolute;
              width: ${action.w}%;
              height: ${action.h}%;
            }`;
      },
    );

    // setInjectStyles(
    injectStylesFunc = injectStyles([
      posMobPositions.join(""),
      posBossPositions.join(""),
      posDoorsPositions.join(""),
      posActionScss.join(""),
    ]);
    // );
    if (isVillage(store.getState())) {
      store.dispatch(updateAttackState(false));
    } else {
      store.dispatch(updateAttackState(true));
    }

    changeLoading(false);
  }

  function changeDoor(door: any) {
    // Check if user can do that
    socket.send("change location", door.next_loc_id);
  }

  return (
    <>
      <div className={`${styles.Location}`} id="location-parent">
        <div
          className={`${styles.LocationInner} ${
            isModalOpened ? "black-background" : ""
          } ${
            location.transform && location.hasAnimate
              ? `${styles.LocationAnimate}`
              : ""
          } location-global-${location.id}`}
        >
          {location.type === "village" ? null : (
            <>
              <div className={`${styles.ChestIcon}`}></div>
              <div
                className={`${styles.ExitIcon}`}
                onClick={() => {
                  socket.send("exit group");
                  changeLoading(true);
                }}
              ></div>
            </>
          )}

          {location.doors.map((door: any, idx: number) => {
            // TODO: Вынести логику стилей в стили
            return (
              <div
                key={idx}
                className={`door-level-${
                  store.getState().location.id
                }-door-${idx}`}
                onClick={() => changeDoor(door)}
              >
                {door.navigateArrow ? DIRECTION[door.navigateArrow] : null}
              </div>
            );
          })}
          {locationsData.data[store.getState().location.id]?.actions && (
            <Actions
              key={actionSeed}
              actions={
                locationsData.data[store.getState().location.id]?.actions
              }
            />
          )}
          {isLoading ? null : <Npcs />}
        </div>
        <HealEffectContainer />
        {isLoading ? null : <Mobs />}
        <LocationIcons />
        <Skill />
        <div id={GROUP_ELEMENT_AT_LOCATION_ID}></div>
        <GroupEndModal />
      </div>
      {backpack.isOpened ? <Backpack /> : ""}
      <ItemInfo />
    </>
  );
}

export default Location;
