import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeEnd from "../TimeEnd/TimeEnd";
import styles from "./GroupEndModal.module.scss";

function GroupEndTable({ result }: any) {
  const isShowDifficulty =
    typeof result.difficulty === "number" &&
    result.difficulty >= 1 &&
    result.difficulty <= 4;

  return (
    <table className={`w-100 ${styles.RateTable} white-text`}>
      <thead>
        <tr>
          {result.name ? <th>Имя</th> : null}
          <th>Время</th>
          {isShowDifficulty ? <th className="text-center">Сложность</th> : null}
          <th>Урон</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {result.name ? <th>{result.name}</th> : null}

          <td>
            <TimeEnd showMs={true} time={result.time} />
          </td>
          {isShowDifficulty ? (
            <td className={`text-center ${styles.RateDifficulty}`}>
              {new Array(result.difficulty).fill(null).map((i, idx) => {
                return <FontAwesomeIcon key={idx} icon={faStar} />;
              })}
            </td>
          ) : null}
          <td>{result.damage}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default GroupEndTable;
