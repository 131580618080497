import React from "react";
import styles from "./LoaderBtn.module.scss";

const LoaderBtn = ({ isActive }: any) => {
  return (
    <div className={styles.loaderContainer}>
      {isActive ? <div className={styles.loader}></div> : null}
    </div>
  );
};

export default LoaderBtn;
