import { request } from "../common";
import {
  API_AUTH_CHECK,
  API_AUTH_LOGIN,
  API_AUTH_LOGOUT,
  API_AUTH_UPDATE_TOKEN,
} from "../constants";

export const customUserApi = {
  checkAuth: async () => {
    return await request({
      data: null,
      method: "POST",
      url: `${API_AUTH_CHECK}`,
      withCredentials: true,
    });
  },
  login: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_AUTH_LOGIN}`,
      withCredentials: true,
    });
  },
  logout: async () => {
    return await request({
      data: null,
      method: "POST",
      url: `${API_AUTH_LOGOUT}`,
      withCredentials: true,
    });
  },
  updateToken: async () => {
    return await request({
      data: null,
      method: "POST",
      url: `${API_AUTH_UPDATE_TOKEN}`,
      withCredentials: true,
    });
  },
};

export const { checkAuth, login, logout, updateToken } = customUserApi;
