import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import "./index.scss";
import store from "./services/store.service";

const container = document.getElementById("root")!;
const root = createRoot(container);
import Main from "./modules/main";

root.render(
  <Provider store={store}>
    <Main />
  </Provider>,
);
