import { request } from "../common";
import {
  API_ITEM_LIST,
  API_PUT_OR_GET_FROM_BANK,
  API_CRAFT_ITEM,
  API_EXCHANGE_ITEM,
  API_TRADE_ITEM,
  API_TRADE_LIST,
  API_TRADE_RETURN_MY_ITEM,
  API_TRADE_BUY_ITEM,
} from "../constants";

export const customUserApi = {
  buyItem: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_TRADE_BUY_ITEM}`,
      withCredentials: true,
    });
  },
  craftItem: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_CRAFT_ITEM}`,
      withCredentials: true,
    });
  },
  exchangeItem: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_EXCHANGE_ITEM}`,
      withCredentials: true,
    });
  },
  getList: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_ITEM_LIST}`,
      withCredentials: true,
    });
  },
  getTradeList: async (type: any) => {
    return await request({
      method: "GET",
      params: { type },
      url: `${API_TRADE_LIST}`,
      withCredentials: true,
    });
  },
  putOrGetFromBank: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_PUT_OR_GET_FROM_BANK}`,
      withCredentials: true,
    });
  },
  takeMyTradeItem: async (tradeItemId: any) => {
    return await request({
      data: { tradeItemId },
      method: "POST",
      url: `${API_TRADE_RETURN_MY_ITEM}`,
      withCredentials: true,
    });
  },
  tradeItem: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_TRADE_ITEM}`,
      withCredentials: true,
    });
  },
};

export const {
  buyItem,
  craftItem,
  exchangeItem,
  getList,
  getTradeList,
  putOrGetFromBank,
  takeMyTradeItem,
  tradeItem,
} = customUserApi;
