import { useEffect, useRef, useState } from "react";
import styles from "./Npc.module.scss";
import store from "../../../services/store.service";
import React from "react";
import { loadJson, random } from "../../../services/base/list";

import { socketConnection } from "../../../core/api/common";
import { NPC_PREFIX } from "../../../services/base/constants";
import { updateDialog } from "../../../services/store/dialog/dialog.store";

function Npc({ npc }: any) {
  const npcRef: any = useRef(null);
  const [socket]: any = useState(socketConnection());
  const [stylesOfNpc, changeStyles]: any = useState({});

  const [NpcId, setNpcId]: any = useState("");

  useEffect(() => {
    const timings = random(0.65, 1.25, false) + "s";
    setNpcId(`${NPC_PREFIX}${npc.id}${npc.uniqId}`);

    changeStyles({
      stay: { animation: `npc-animate-stay-${npc.id} ${timings} infinite` },
    });
  }, []);

  return (
    <>
      <div
        ref={npcRef}
        className={`${styles.NpcConainer}`}
        id={NpcId}
        onPointerDown={async ($event: any) => {
          $event?.stopPropagation();
          $event?.preventDefault();
          const dialog = await loadJson(`npc/dialogs/${npc.id}.json`);
          store.dispatch(updateDialog(dialog));
        }}
        style={npc.style}
      >
        <div
          key={npc.state}
          className={`${styles.Npc} ${styles[`Npc-${npc.id}`]}`}
          style={stylesOfNpc[npc.state]}
        ></div>
      </div>
    </>
  );
}

export default React.memo(Npc);
