import { useEffect, useState } from "react";
import styles from "./GroupEndModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCircleLeft,
  faLeftLong,
  faPerson,
  faPlus,
  faRightLong,
  faStar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { socketConnection } from "../../../../core/api/common";
import ItemsPager from "../ItemsPager/ItemsPager";
import TimeEnd from "../TimeEnd/TimeEnd";
import { useSelector } from "react-redux";
import store from "../../../../services/store.service";
import { setGroupEnd } from "../../../../services/store/other/other.store";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import { MapRate } from "../Map/Map";
import GroupEndTable from "./GroupEndTable";

const RESULTS: any = {
  0: "Результат боя",
  1: "Дневной рекорд",
  2: "Добыча",
};

function GroupCurrentRate({ result }: any) {
  return (
    <div>
      <div className="d-flex justify-center">
        <div className={`${styles.GroupImg}`}>
          <div
            className={` ${result.isFinished ? styles.Victory : styles.Lose}`}
          ></div>
          {result.isFinished ? (
            <div
              className={`${styles.GroupResult} d-flex align-items-center justify-center`}
            >
              <div className="w-100 white-text px-2">
                <GroupEndTable
                  result={{
                    damage: result.damage,
                    difficulty: result.difficulty,
                    time: result.time,
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {result.isNewPersonalRecord || !result.isFinished ? null : (
        <div className="mt-4">
          <div>
            <h4 className="mb-2 mt-0">Ваш лучший результат</h4>
            <div className="w-100 white-text">
              <GroupEndTable result={result.personalRecord} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function GroupEndModal() {
  const [socket]: any = useState(socketConnection());
  const { groupEnd } = useSelector((store: any) => store.other);

  const [currentStep, changeStep] = useState(0);
  const CLOSE_STEP_SUCCESS = 2;

  function close() {
    store.dispatch(setGroupEnd(null));
    socket.send("update info");
    changeStep(0);
  }

  function next() {
    if (currentStep >= CLOSE_STEP_SUCCESS || !groupEnd.isFinished) {
      close();
      return;
    }

    changeStep(currentStep + 1);
  }

  return (
    <>
      {groupEnd ? (
        <ActionModalWithButtons
          buttons={[
            {
              action: () => {
                next();
              },
              title: `${
                currentStep >= CLOSE_STEP_SUCCESS || !groupEnd.isFinished
                  ? "Закрыть"
                  : "Дальше →"
              }`,
            },
            {
              action: () => {
                changeStep(currentStep - 1);
              },
              isHidden: currentStep < 1,
              title: "Назад",
            },
          ]}
          info={
            groupEnd.isFinished ? null : (
              <div className={"white-text warn-description"}>
                <div className={"warn-description-icon"}>
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </div>
                <p className={"mt-0 mb-0 warn-description-text"}>
                  Отличная попытка! Возможно, стоит сменить уровень или
                  проверить экипировку — правильное снаряжение часто меняет
                  исход боя.
                </p>
              </div>
            )
          }
          onClose={() => {
            close();
          }}
          toTop={true}
          withBkg={true}
        >
          <div className="white-text">
            <h2 className="mt-0 text-center mb-2">{RESULTS[currentStep]}</h2>
            {currentStep === 0 ? <GroupCurrentRate result={groupEnd} /> : null}
            {currentStep === 1 ? (
              <MapRate
                difficulty={groupEnd.difficulty}
                fillFor10IfEmpty={false}
                levelId={groupEnd.levelId}
                showOnlyTable={true}
              />
            ) : null}
            {currentStep === 2 ? (
              <ItemsPager
                items={groupEnd.rewards}
                pageCount={16}
                showLock={false}
                showPagination={false}
                type="rewards"
              ></ItemsPager>
            ) : null}
            {/* {currentStep === 3 ? (
              <GroupEndTable result={groupEnd.bestRecord} />
            ) : null} */}
          </div>
        </ActionModalWithButtons>
      ) : null}
    </>
  );
}

export default GroupEndModal;
