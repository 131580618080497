import React from "react";
import { useEffect, useRef, useState } from "react";
import { random } from "../../../services/base/list";
import styles from "./AnimationAttack.module.scss";

function AnimationAttack({ type, value }: any) {
  const shift = 64 / 2;
  const hasNegativeXValue = random(0, 2) === 1 ? "-" : "";

  const [stylesOfAttack, changeStyles] = useState("");

  const [showComponent, changeShowComponent] = useState(true);

  useEffect(() => {
    const timeout: any = setTimeout(() => {
      changeStyles(
        `translate(${hasNegativeXValue}${shift}px, ${random(-shift, shift)}px)`,
      );
    }, 200);
    setTimeout(() => {
      changeShowComponent(false);
    }, 950);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {!showComponent ? null : (
        <div
          className={styles.AnimationAttack}
          style={{ animation: `animate-effect-${type} 0.65s` }}
        >
          {type === "miss" ? null : (
            <span
              className={`${styles.AnimationAttackValue} ${
                type === "crit" ? styles.crit : ""
              }`}
              style={{ transform: stylesOfAttack }}
            >
              {value}
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default React.memo(AnimationAttack);
