import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Map.module.scss";
import store from "../../../../services/store.service";

import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import Mob from "../../mobs/Mob";
import { getMobsInfo } from "../../../../core/api/other/other.api";
import Boss from "../../mobs/Boss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { selectItem } from "../../../../services/store/items/items.store";
import { ItemCell } from "../ItemsPager/ItemsPager";

function MapBook({ buildName, onClose }: any) {
  const socket = socketConnection();
  const storage = useSelector((store: any) => store.other);
  const [mobs, changeMobs]: any = useState([]);
  const [selectedMob, changeSelectedMob]: any = useState(null);

  useEffect(() => {
    getMobsInfo({ buildName }).then((resp: IRequestResponse) => {
      const buildMobs = resp.getData();
      if (!buildMobs) return;
      const mobs: any = [];

      if (buildMobs.mobs) {
        Object.keys(buildMobs.mobs).forEach(mobId => {
          mobs.push({
            items: buildMobs.mobs[mobId],
            mobId,
            type: "mob",
          });
        });
      }

      if (buildMobs.bosses) {
        Object.keys(buildMobs.bosses).forEach(mobId => {
          mobs.push({
            items: buildMobs.bosses[mobId],
            mobId,
            type: "boss",
          });
        });
      }

      changeMobs(mobs);
    });
  }, []);

  return (
    <ActionModalWithButtons
      buttons={[]}
      info={
        <div className={"white-text warn-description"}>
          <div className={"warn-description-icon"}>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </div>
          <p className={"mt-0 mb-0 warn-description-text"}>
            В зависимости от сложности, предметы могут выпадать с разным шансом
            или не выпадать вовсе.
          </p>
        </div>
      }
      onClose={() => {
        if (selectedMob) {
          changeSelectedMob(null);
        } else {
          onClose();
        }
      }}
      toTop={true}
      withBkg={true}
    >
      <h2 className="my-0 text-center white-text mb-4">
        {selectedMob ? "Предметы" : "Обитатели"}
      </h2>
      <div className={`ItemsList d-flex ${styles.MapBookList}`}>
        {selectedMob && selectedMob.items && selectedMob.items.length
          ? selectedMob.items.map((itemId: any) => {
              const item = `${itemId}|0|1`;
              return (
                <div
                  key={itemId}
                  className="Item"
                  onClick={() => {
                    store.dispatch(
                      selectItem({
                        item,
                        type: "dungeon",
                      }),
                    );
                  }}
                >
                  <ItemCell item={item} withCount={true} withLevel={true} />
                </div>
              );
            })
          : mobs.map((mob: any) => {
              return (
                <div
                  key={`${mob.mobId}|${mob.type}`}
                  className="Item"
                  onClick={() => {
                    changeSelectedMob(mob);
                  }}
                >
                  {mob.type === "mob" ? (
                    <Mob
                      params={{ id: mob.mobId, state: "stay" }}
                      showImage={true}
                    ></Mob>
                  ) : (
                    <Boss
                      params={{ id: mob.mobId, state: "stay" }}
                      showImage={true}
                    ></Boss>
                  )}
                </div>
              );
            })}
      </div>
    </ActionModalWithButtons>
  );
}

export default MapBook;
