import { useState } from "react";
import { IRequestResponse } from "../../core/api/common";
import { login } from "../../core/api/user/user.api";
import styles from "./Login.module.scss";

function Login() {
  const [password, changePassword] = useState("");
  const [results, changeResults] = useState("");

  const tryLogin = async (event: any) => {
    changeResults("load");
    event.preventDefault();
    event.stopPropagation();

    const params = new URLSearchParams(document.location.search);
    const hashCode = params.get("token") || localStorage.getItem("hashCode");

    if (hashCode) localStorage.setItem("hashCode", hashCode);

    const response: IRequestResponse = await login({
      hashCode,
      password,
    });

    if (response.isOk) {
      // navigate("/");
      location.href = "/";
    } else {
      changeResults(response.getFailMessage);
    }
  };

  return (
    <div className={styles["form-container"]}>
      <div className={styles["form-content"]}>
        <div>
          <h2 className={styles["form-title"]}>Авторизация</h2>
          <div className={styles["form-inner"]}>
            <form onSubmit={tryLogin}>
              <input
                className={`${styles.password} input-field`}
                onChange={(e: any) => {
                  changePassword(e.target.value);
                }}
                placeholder="Введите пароль"
                type="password"
                value={password}
              />
              <button
                className={styles["submit-btn"]}
                onClick={tryLogin}
                type="submit"
              >
                Авторизоваться
              </button>
            </form>
            <div className="text-left">
              <a
                className={styles["form-link"]}
                href="https://t.me/enchantedworld_bot"
                rel="noreferrer"
                target="_blank"
              >
                Впервые здесь?
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* {results} */}
    </div>
  );
}

export default Login;
