import { useEffect, useState } from "react";
import { getTimeEnd } from "../../../../services/base/time";

const timeUpd = 250;
function TimeEnd({ isUpdate, showMs, time }: any) {
  const [timeForEnd, setTimeForEnd]: any = useState(null);
  const [latestInterval, setLatestInterval]: any = useState(null);

  function updateTime() {
    if (latestInterval) clearInterval(latestInterval);
    isUpdate &&
      setLatestInterval(
        setInterval(() => {
          setTimeForEnd((oldValue: any) => {
            return oldValue - timeUpd;
          });
        }, timeUpd),
      );
  }

  useEffect(() => {
    updateTime();
    return () => {
      clearInterval(latestInterval);
    };
  }, []);

  useEffect(() => {
    setTimeForEnd(time);
  }, [time]);

  return <>{timeForEnd ? getTimeEnd(timeForEnd, !!showMs) : null}</>;
}

export default TimeEnd;
