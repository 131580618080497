import { createSlice } from "@reduxjs/toolkit";

export const Modal = createSlice({
  initialState: {
    tradeStep: 0,
  },
  name: "modal",
  reducers: {
    setCurrentTradeStep: (state, action) => {
      state.tradeStep = action.payload;
    },
  },
});

export const { setCurrentTradeStep } = Modal.actions;
export default Modal.reducer;
