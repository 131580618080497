import { request } from "../common";
import {
  API_DICTIONARY,
  API_SERVER_TIME,
  API_DUNGEONS,
  API_MOBS,
} from "../constants";

export const customUserApi = {
  getDictionary: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_DICTIONARY}`,
      withCredentials: true,
    });
  },
  getDungeons: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_DUNGEONS}`,
      withCredentials: true,
    });
  },
  getMobsInfo: async (params: any) => {
    return await request({
      cacheTimeout: Infinity,
      method: "GET",
      params,
      url: `${API_MOBS}`,
      useCache: true,
      withCredentials: true,
    });
  },
  getServerTime: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_SERVER_TIME}`,
      withCredentials: true,
    });
  },
};

export const { getDictionary, getDungeons, getMobsInfo, getServerTime } =
  customUserApi;
