import { useEffect, useRef, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { checkAuth, logout } from "../../core/api/user/user.api";
import GameWrapper from "../game";
import Login from "../login";
import Loader from "../game/base/Loader/Loader";

const router = createBrowserRouter([
  {
    element: <GameWrapper />,
    path: "/",
  },
  {
    element: <Login />,
    path: "/login",
  },
]);
function Main() {
  const [isLoading, changeStatus] = useState(true);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(document.location.search);

      const hashCode = params.get("token") || localStorage.getItem("hashCode");
      if (hashCode && localStorage.getItem("hashCode") !== hashCode) {
        await logout();
      }

      checkLogin();
    })();
  }, []);

  function checkLogin() {
    checkAuth().then(async response => {
      if (response.hasError && location.pathname !== "/login") {
        location.href = "/login";
        // await logout();
        return;
      }

      if (response.isOk && location.pathname === "/login") {
        location.href = "/";
        return;
      }

      setTimeout(() => {
        changeStatus(false);
      }, 300);
    });
  }

  return <>{isLoading ? "" : <RouterProvider router={router} />}</>;
}

export default Main;
