import { useEffect, useState } from "react";
import styles from "./TopToolbar.module.scss";
import store from "../../../services/store.service";
import { toggleChat } from "../../../services/store/other/other.store";

import { useSelector } from "react-redux";

function TopToolbar() {
  const { isChatOpened } = useSelector((store: any) => store.other);

  return (
    <>
      {/* {isChatOpened ? null : ( */}
      <div
        className={styles.TopToolbar}
        onClick={() => {
          store.dispatch(toggleChat(!isChatOpened));
        }}
      >
        {isChatOpened ? null : (
          <div className={styles.ChatShortContainer}>
            <div className={styles.ChatShort}>
              <div>[Group][Master] Hello! I am new here</div>
              <div>[Master]: What to do here and how to earn money?</div>
            </div>
            <div className={styles.ChatIcon}></div>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
}

export default TopToolbar;
