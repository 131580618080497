import { createSlice } from "@reduxjs/toolkit";

export interface BackpackState {
  isOpened: boolean;
}

export const Backpack = createSlice({
  initialState: {
    isOpened: false,
  },
  name: "backpack",
  reducers: {
    toggle: (state, action) => {
      state.isOpened = action.payload;
    },
  },
});

export const { toggle } = Backpack.actions;
export default Backpack.reducer;
