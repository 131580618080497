import { createSlice } from "@reduxjs/toolkit";

export const Dialog = createSlice({
  initialState: {
    actionId: 0,
    dialog: null,
  },
  name: "dialog",
  reducers: {
    clearDialog: (state: any) => {
      state.actionId = 0;
      state.dialog = null;
    },
    updateDialog: (state: any, action: any) => {
      state.dialog = action.payload;
    },
    updateDialogActionId: (state: any, action: any) => {
      state.actionId = action.payload;
    },
  },
});

export const { clearDialog, updateDialog, updateDialogActionId } =
  Dialog.actions;
export default Dialog.reducer;
