import { useEffect, useState } from "react";
import Game from "./game";
import { updateToken } from "../../core/api/user/user.api";
import Loader from "./base/Loader/Loader";

function GameWrapper() {
  const [isLoading, setLoading] = useState(true);
  const [isHideLoader, setIsHideLoader] = useState(false);
  useEffect(() => {
    if (process.env.REACT_APP_IS_PROD === "true") {
      updateToken().then(() => {
        loadFalse();
      });
    } else {
      loadFalse();
    }
  }, []);

  function loadFalse() {
    setLoading(false);
  }

  return (
    <>
      <Loader
        isLoading={!isHideLoader}
        onEndLoaded={() => {
          setTimeout(() => {
            setIsHideLoader(true);
          }, 1500);
        }}
      />

      {isLoading ? null : <Game />}
    </>
  );
}

export default GameWrapper;
