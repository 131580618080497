import { useEffect, useState } from "react";
import styles from "./GetProps.module.scss";
import { useSelector } from "react-redux";
import {
  parseItem,
  calcAttrFromBase,
  calcAttrFromBaseWithAddition,
} from "../../../../services/base/item";
import React from "react";
import { getTranslateValue } from "../../../../services/base/translate";

const DICTIONARY: any = {
  accuracy: "Точность",
  agility: "Ловкость",
  damage: "Урон",
  defence: "Защита",
  intelligence: "Интеллект",
  maxhp: "ХП",
  maxmp: "МП",
  regen_hp: "Здоровье",
  regen_mp: "Мана",
  speed: "Скорость",
  strength: "Сила",
};

export function GetItemName({ item }: any) {
  const { items } = useSelector((store: any) => store.items).data;
  const [itemId, level, count, increase] = parseItem(item);
  const itemInfo = { ...items[itemId] };

  return (
    <div className={styles.PropsName}>
      {getTranslateValue(itemInfo?.name, "items")}
    </div>
  );
}

function GetProps({ diffItem, item, showName = true }: any) {
  const { collections, items } = useSelector((store: any) => store.items).data;
  const [info, changeInfo]: any = useState(null);
  const [diffInfo, changeDiffInfo]: any = useState(null);

  function calcTotalParams(
    collectionType: string,
    itemParams: any,
    increase: number | string,
  ) {
    const collection = collections[collectionType];
    const params: any = {};

    Object.keys(itemParams).forEach(attrName => {
      params[attrName] = 0;
      if (collection.increasePercent && collection.increasePercent[attrName]) {
        params[attrName] += calcAttrFromBase(
          collection.baseParams[attrName],
          collection.increasePercent[attrName],
          increase,
        );
      }
      if (collection.increaseParams && collection.increaseParams[attrName]) {
        params[attrName] += calcAttrFromBaseWithAddition(
          collection.baseParams[attrName],
          collection.increaseParams[attrName],
          increase,
        );
      }
    });
    return params;
  }

  function getDiffParamValue(firstItemParam: any, secondItemParam: any) {
    if (!secondItemParam) return;
    if (firstItemParam === secondItemParam) return;
    const diffValues = firstItemParam - secondItemParam;

    if (diffValues < 0) {
      return <span className={"red-text ml-1"}>({diffValues})</span>;
    }
    return <span className={"green-text ml-1"}>(+{diffValues})</span>;
  }

  useEffect(() => {
    const updateInfo = (item: any, changeFunction: any) => {
      if (item) {
        const [itemId, level, count, increase] = parseItem(item);
        const itemInfo = { ...items[itemId] };

        if (collections[itemInfo.collection_type]) {
          itemInfo.params = calcTotalParams(
            itemInfo.collection_type,
            {
              ...itemInfo.params,
              ...collections[itemInfo.collection_type].baseParams,
            },
            increase,
          );
        }

        changeFunction(itemInfo);
      } else {
        changeFunction(null);
      }
    };

    updateInfo(item, changeInfo);
    if (diffItem) {
      updateInfo(diffItem, changeDiffInfo);
    }
  }, [item]);

  return (
    <>
      {info ? (
        <div className={styles.PropsParam}>
          {showName && <GetItemName item={item} />}
          {info && info.params
            ? Object.keys(info.params).map(key => {
                return (
                  <div key={key}>
                    {DICTIONARY[key] || key}:{" "}
                    <span className={styles.ParamValue}>
                      {JSON.stringify(info.params[key])}
                    </span>
                    {diffInfo && diffInfo.params
                      ? getDiffParamValue(
                          info.params[key],
                          diffInfo.params[key],
                        )
                      : null}
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
    </>
  );
}

export default React.memo(GetProps);
