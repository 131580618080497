import { useEffect, useState } from "react";

import FullModal from "../../FullModal/FullModal";
import styles from "./DialogLeksli.module.scss";
import ItemsPager from "../../ItemsPager/ItemsPager";
import { useSelector } from "react-redux";

function DialogLeksli({ onClose, type }: any) {
  const { icons, title }: any = { icons: [], title: "Создать предмет" };
  const [activeType, changeActiveType] = useState(icons[0]?.type);
  const { craftedItems } = useSelector((store: any) => store.items).data;
  const [leksliItems, changeLeksliItems]: any = useState([]);

  useEffect(() => {
    changeLeksliItems(
      Object.values(craftedItems)
        .filter((item: any) => item.type === "leksli")
        .map((item: any) => item.result),
    );
  }, []);

  return (
    <>
      <FullModal
        buttons={{
          active: activeType,
          list: icons,
          onClick: (type: string) => {
            changeActiveType(type);
          },
        }}
        hideBottom={true}
        isDisabledGlobalPadding={true}
        isDisabledPadding={true}
        onClose={() => {
          onClose();
        }}
        title={title}
      >
        <div className={styles.LeksliContainer}>
          <ItemsPager
            items={leksliItems}
            maxCount={leksliItems.length}
            pageCount={leksliItems.length}
            showLock={false}
            showPagination={false}
            type={"craft"}
          />
        </div>
      </FullModal>
    </>
  );
}

export default DialogLeksli;
