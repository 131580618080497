import { useEffect, useRef, useState } from "react";
import React from "react";

import styles from "./ScarecrowTime.module.scss";
import { ACTION_TYPES_ID } from "../../../../../services/base/constants";
import { socketConnection } from "../../../../../core/api/common";
import { getTimeDiff } from "../../../../../services/base/time";

function ScarecrowTime({ action }: any) {
  const [time, updateTime]: any = useState("");
  const [interval, updateInterval]: any = useState(null);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    socket.send("get action info", { id: action.id });
  }, []);

  useEffect(() => {
    if (!action.data) return;
    if (action.data.timeDiff) {
      clearInterval(interval);
      updateTime(getTimeDiff(action.data.timeDiff, 3));
      return;
    }

    if (action.data.timeStart && !interval) {
      updateInterval(
        setInterval(() => {
          updateTime(getTimeDiff(+new Date() - action.data.timeStart, 3));
          if (action.data.timeDiff) clearInterval(interval);
        }, 30),
      );
    }
  }, [action.data]);

  return (
    <>
      <div
        className={styles.ScarecrowTime}
        onClick={() => {
          socket.send("do action", {
            id: ACTION_TYPES_ID.ScarecrowAttack,
            reset: true,
          });

          socket.send("get action info", {
            id: ACTION_TYPES_ID.ScarecrowTime,
            reset: true,
          });
        }}
      >
        {time}
      </div>
    </>
  );
}

export default ScarecrowTime;
