import { useEffect, useRef, useState } from "react";
import styles from "./ItemInfo.module.scss";
import store from "../../../../services/store.service";
import { useSelector } from "react-redux";

import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import { getTranslateValue } from "../../../../services/base/translate";
import { setTempQuickItemPane } from "../../../../services/store/other/other.store";
import { selectItem } from "../../../../services/store/items/items.store";
import { ItemCell } from "../ItemsPager/ItemsPager";
import React from "react";
import GetProps from "../GetProps/GetProps";
import ImproveItemModal from "../ImproveItemModal/ImproveItemModal";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import TgCoin from "../TgCoin/TgCoin";
import CustomRange from "../CustomRange/CustomRange";
import { parseItem } from "../../../../services/base/item";
import {
  putOrGetFromBank,
  takeMyTradeItem,
  tradeItem,
  buyItem,
} from "../../../../core/api/items/items.api";
import CreateItemModal from "../CreateItemModal/CreateItemModal";
import ExchangeItemModal from "../ExchangeItemModal/ExchangeItemModal";
import { TG_ICON } from "../../../../services/base/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import AdditionalyMenu from "./AdditionalyMenu";

const DEFAULT_RANGE_VALUES: any = { count: 1, price: 0.0001 };

function hasTypeAdditionalInfo(type: string) {
  const additionalInfo: any = {
    "bank.get": 1,
    "bank.put": 1,
    sell: 1,
    trade: 1,
    "trade.my": 1,
  };
  return !!additionalInfo[type];
}

function ItemInfo() {
  const { selectedItem } = useSelector((store: any) => store.items);

  const [socket]: any = useState(socketConnection());
  const [improveItem, changeImproveItemId]: any = useState(null);

  const statesForCustomRange: any = {
    count: useState(DEFAULT_RANGE_VALUES.count),
    price: useState(DEFAULT_RANGE_VALUES.price),
  };

  function setStateRangeDefault() {
    Object.keys(statesForCustomRange).forEach(stateKey => {
      statesForCustomRange[stateKey][1](DEFAULT_RANGE_VALUES[stateKey]);
    });
  }

  const btns = [
    {
      action: () => {
        socket.send("use item", selectedItem.inventoryId);
        close();
      },
      title: "Использовать",
      type: ["backpack"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];

        buyItem({
          count: countValue,
          tradeItemId: selectedItem.tradeItemId,
        }).then((resp: IRequestResponse) => {
          if (resp.hasError) return;
          close();
        });
      },
      title: "Купить",
      type: ["trade"],
    },
    {
      action: () => {
        takeMyTradeItem(selectedItem.tradeItemId).then(
          (resp: IRequestResponse) => {
            if (resp.hasError) return;
          },
        );
        close();
      },
      title: "Убрать с продажи",
      type: ["trade.my"],
    },
    {
      action: () => {
        store.dispatch(setTempQuickItemPane(selectedItem.inventoryId));
        close();
      },
      title: "Закрепить",
      type: ["backpack"],
    },
    {
      action: () => {
        changeImproveItemId(selectedItem.item);
      },
      title: "Усилить",
      type: ["backpack"],
    },
    {
      action: () => {
        store.dispatch(selectItem({ ...selectedItem, type: "sell" }));
      },
      title: "Продать",
      type: ["backpack"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];
        const priceValue = statesForCustomRange.price[0];

        tradeItem({
          info: `${selectedItem.inventoryId}:${countValue}:${priceValue}`,
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Выставить на продажу",
      type: ["sell"],
    },
    {
      action: () => {
        socket.send("delete item", selectedItem.inventoryId);
        close();
      },
      title: "Выбросить",
      type: ["backpack"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${countValue}`],
          type: "put",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Положить",
      type: ["bank.put"],
    },
    {
      action: () => {
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${9999999}`],
          type: "put",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Положить все",
      type: ["bank.put"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${countValue}`],
          type: "get",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Взять",
      type: ["bank.get"],
    },
    {
      action: () => {
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${9999999}`],
          type: "get",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Взять все",
      type: ["bank.get"],
    },
  ];

  function close() {
    store.dispatch(selectItem(null));
    setStateRangeDefault();
  }

  return (
    <>
      {selectedItem ? (
        <div className={styles.InfoItemContainer}>
          {selectedItem.type === "craft" && (
            <CreateItemModal
              item={selectedItem.item}
              onClose={() => {
                store.dispatch(selectItem(null));
              }}
            />
          )}

          {selectedItem.type === "exchange" && (
            <ExchangeItemModal
              item={selectedItem.item}
              onClose={() => {
                store.dispatch(selectItem(null));
              }}
            />
          )}

          {selectedItem.type !== "craft" && selectedItem.type !== "exchange" && (
            <ActionModalWithButtons
              buttons={btns.filter((btn: any) =>
                btn.type.includes(selectedItem.type),
              )}
              info={
                hasTypeAdditionalInfo(selectedItem.type) ? (
                  <AdditionalyMenu
                    rangeStates={statesForCustomRange}
                    selectedItem={selectedItem}
                  />
                ) : null
              }
              onClose={() => {
                close();
              }}
            >
              <div className={`${styles.InfoItem}`}>
                <div className={styles.InfoItemItem}>
                  <div className={`${styles.InfoItemItemCell} ItemsList`}>
                    <div className="Item DisableHover">
                      <ItemCell
                        item={selectedItem.item}
                        withCount={!!selectedItem.withCount}
                        withLevel={true}
                      />
                    </div>
                  </div>

                  <div className={styles.InfoItemItemParams}>
                    <GetProps item={selectedItem.item}></GetProps>
                  </div>
                </div>
              </div>
            </ActionModalWithButtons>
          )}

          {improveItem ? (
            <ImproveItemModal
              inventoryId={selectedItem.inventoryId}
              item={improveItem}
              onClose={() => {
                changeImproveItemId(null);
                close();
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default React.memo(ItemInfo);
