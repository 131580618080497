import { useEffect, useState } from "react";
import HealEffect from "./HealEffect";
import { useSelector } from "react-redux";

export default function HealEffectContainer() {
  const other = useSelector((store: any) => store.other);

  useEffect(() => {
    //
  }, []);

  return (
    <div>
      {other.heal.map((healTimeout: number) => {
        return <HealEffect key={healTimeout} />;
      })}
    </div>
  );
}
