import { createSelector, createSlice } from "@reduxjs/toolkit";

const TYPE_VILLAGE = "village";

function getVillageLocation(data: any) {
  return Object.values(data).filter((location: any) => {
    return location.type === TYPE_VILLAGE;
  });
}

export const Location = createSlice({
  initialState: {
    data: {},
    id: 18,
  },
  name: "location",
  reducers: {
    changeLocation: (state, action) => {
      state.id = action.payload;
    },

    setLocationData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const getVillage = createSelector(
  (state: any) => state.location.data,
  getVillageLocation,
);

export const isVillage = createSelector(
  (state: any) => state.location,
  location => {
    if (!location.data[location.id]) return false;
    return location.data[location.id].type === TYPE_VILLAGE;
  },
);

export const getAllNPCVillage = createSelector(
  (state: any) => state.location.data,
  data => {
    const npc: any = [];
    getVillageLocation(data).forEach((location: any) => {
      npc.push(...location.npcs);
    });

    return npc;
  },
);

export const { changeLocation, setLocationData } = Location.actions;
export default Location.reducer;
