import { useEffect, useState } from "react";

import FullModal from "../../FullModal/FullModal";
import styles from "./DialogRaks.module.scss";
import ItemsPager from "../../ItemsPager/ItemsPager";
import { useSelector } from "react-redux";

function DialogRaks({ onClose, type }: any) {
  const { icons, title }: any = { icons: [], title: "Создать предмет" };

  const [activeType, changeActiveType] = useState(icons[0]?.type);
  const { craftedItems } = useSelector((store: any) => store.items).data;
  const [raksItems, changeRaksItems]: any = useState([]);

  useEffect(() => {
    changeRaksItems(
      Object.values(craftedItems)
        .filter((item: any) => item.type === "raks")
        .map((item: any) => item.result),
    );
  }, []);

  return (
    <>
      <FullModal
        buttons={{
          active: activeType,
          list: icons,
          onClick: (type: string) => {
            changeActiveType(type);
          },
        }}
        hideBottom={true}
        isDisabledGlobalPadding={true}
        isDisabledPadding={true}
        onClose={() => {
          onClose();
        }}
        title={title}
      >
        <div className={styles.RaksContainer}>
          <ItemsPager
            items={raksItems}
            maxCount={raksItems.length}
            pageCount={raksItems.length}
            showLock={false}
            showPagination={false}
            type={"craft"}
          />
        </div>
      </FullModal>
    </>
  );
}

export default DialogRaks;
