import { useEffect, useState } from "react";

import store from "../../../services/store.service";
import React from "react";
import { IRequestResponse, socketConnection } from "../../../core/api/common";
import { useSelector } from "react-redux";
import Npc from "./Npc";
import {
  getAllNPCVillage,
  isVillage,
} from "../../../services/store/location/location.store";

function Npcs() {
  const npcData: any = useSelector((store: any) => store.npc.data);
  const locationData: any = useSelector((store: any) => store.location);

  const npcVillage = useSelector(getAllNPCVillage);

  const [npcs, changeNpc]: any = useState([]);

  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    changeNpc(genNpc());
  }, []);

  useEffect(() => {
    changeNpc(genNpc());
  }, [store.getState().location.id]);

  function genNpc() {
    return (locationData?.data[store.getState().location.id]?.npcs || []).map(
      (npc: any, idx: number) => {
        return {
          ...npcData[npc.id],
          state: "stay",
          style: { left: `${npc.x}%`, top: `${npc.y}%` },
          uniqId: idx,
        };
      },
    );
  }

  return (
    <>
      {npcs.length
        ? npcs.map((npc: any) => {
            return <Npc key={`${npc.id}`} npc={npc} />;
          })
        : null}
    </>
  );
}

export default React.memo(Npcs);
