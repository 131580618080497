import { useEffect, useState } from "react";
import styles from "./MobHealthBar.module.scss";

function MobHealthBar({ hideIfFull, percent }: any) {
  const [cells, updateState]: any = useState([]);

  useEffect(() => {
    updateState(getCurrentArray(percent));
  }, []);

  if (percent === 100 && hideIfFull) return null;
  return (
    <div className={styles.MobHealthCells}>
      {cells.map(({ color, percent }: any, idx: number) => {
        let classes = styles.Cell;

        if (percent <= 20) {
          classes += ` ${styles.CellLow}`;
        }
        return (
          <div
            key={idx}
            className={classes}
            style={{ backgroundColor: `${color}` }}
          ></div>
        );
      })}
    </div>
  );
}

export default MobHealthBar;

function getCurrentArray(percent: number) {
  const maxCount = 5;
  const newArrayValues = [];
  const maxPercentCellValue = 100 / maxCount;

  for (let i = 0; i < maxCount; i++) {
    const val = percent - i * maxPercentCellValue;

    newArrayValues.push(getColorValue((val / maxPercentCellValue) * 100));
  }

  return newArrayValues;
}

function getColorValue(percent: number) {
  switch (true) {
    case percent > 100:
      {
        percent = 100;
      }
      break;
    case percent < 0:
      {
        percent = 0;
      }
      break;
  }

  const color = percent === 0 ? "#000" : getColor(percent);

  return { color, percent };
}

function getColor(percent: number) {
  // переводим процент в дробное число от 0 до 1
  const fraction = percent / 100;

  // вычисляем значения для зеленого и красного цветов
  const green = Math.round(210 * fraction);
  const red = Math.round(210 * (1 - fraction));

  // объединяем значения в hex-код цвета
  const hex = ((red << 16) | (green << 8) | 0).toString(16).padStart(6, "0");

  // возвращаем hex-код
  return "#" + hex;
}
