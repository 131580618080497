import { createSlice } from "@reduxjs/toolkit";
const healAnimateTime = 1400;

export const Other = createSlice({
  initialState: {
    actionSeed: 1,
    dictionary: {},
    effects: [],
    groupEnd: null,
    heal: [],
    isAttacked: false,
    isChatOpened: false,
    isModalOpened: false,
    isSelectLocationOpened: false,
    mobKilled: {},
    paneState: null,
    serverTime: null,
    tempQuikItem: null,
  },
  name: "other",
  reducers: {
    changeEffects: (state, action) => {
      state.effects = action.payload;
    },
    mobKilled: (state: any, action: any) => {
      state.mobKilled = action.payload;
    },
    openSelectLocation: (state, action) => {
      state.isSelectLocationOpened = action.payload;
    },
    setDictionaryData: (state: any, action: any) => {
      state.dictionary = action.payload;
    },
    setGroupEnd: (state, action) => {
      state.groupEnd = action.payload;
    },
    setIsModalOpenedState: (state, action) => {
      state.isModalOpened = action.payload;
    },
    setPaneState: (state, action) => {
      state.paneState = action.payload;
    },
    setServerTime: (state: any, action) => {
      state.serverTime = action.payload;
    },
    setTempQuickItemPane: (state, action) => {
      state.tempQuikItem = action.payload;
    },
    toggleChat: (state, action) => {
      state.isChatOpened = action.payload;
    },
    updateActionSeed: (state: any) => {
      state.actionSeed += 1;
    },
    updateAttackState: (state: any, action) => {
      state.isAttacked = action.payload;
    },
    updateHeal: (state: any) => {
      state.heal.push(+new Date());
      state.heal = state.heal.filter((timeout: number) => {
        return timeout + healAnimateTime > +new Date();
      });
    },
  },
});

export const {
  changeEffects,
  mobKilled,
  openSelectLocation,
  setDictionaryData,
  setGroupEnd,
  setIsModalOpenedState,
  setPaneState,
  setServerTime,
  setTempQuickItemPane,
  toggleChat,
  updateActionSeed,
  updateAttackState,
  updateHeal,
} = Other.actions;
export default Other.reducer;
