export const parseItem = (item: string) => {
  if (!item) return [];
  return item.split("|");
};

export const calcAttrFromBase = (
  baseValue: number,
  percentForLevel: number,
  increase: number | string,
) => {
  return Math.floor(baseValue * Math.pow(percentForLevel, Number(increase)));
};

export const calcAttrFromBaseWithAddition = (
  baseValue: number,
  additionPerLevel: number,
  increase: number | string,
) => {
  return Math.floor(baseValue + additionPerLevel * Number(increase));
};

export const getItemsByType = (list: any, type: string) => {
  return Object.keys(list).filter((itemKey: any) => {
    const itemValue = list[itemKey];

    return itemValue.type === type;
  });
};

export const changeItemsLevel = (list: any, level: any) => {
  return list.map((item: any) => {
    const [itemId, lvl, count, increase]: any = parseItem(item);
    return `${itemId}|${level}|${count}|${increase}`;
  });
};
