import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Effects.module.scss";
import { getTimeDiff } from "../../../../services/base/time";
import { socketConnection } from "../../../../core/api/common";

function Effects() {
  const { effects } = useSelector((store: any) => store.other);
  const socket = socketConnection();

  useEffect(() => {
    socket.send("get effects", null);
  }, []);

  return (
    <div className={`${styles.Effects}`}>
      {effects.map((effect: any) => {
        const time = getTimeDiff(effect.time, 0, true);
        return (
          <div key={effect.id}>
            {time ? (
              <div className={`${styles.EffectElement}`}>
                <div className={`${styles.EffectName}`}>{effect.id}</div>
                <div className={`${styles.EffectTime}`}>{time}</div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default Effects;
