import { getCssImagePath, getImagePath } from "../../scss/styles";

export function injectStyles(stylesArray: any) {
  // Создаем массив стилей
  const styleElements = stylesArray.map((styleContent: string) => {
    const styleElement = document.createElement("style");

    styleElement.textContent = styleContent;
    document.head.appendChild(styleElement);
    return styleElement;
  });

  // Возвращаем функцию для очистки, которая удаляет стили
  return () => {
    styleElements.forEach((styleElement: any) => {
      if (styleElement && document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    });
  };
}

export const initilizeStyles = (
  effects: any,
  items: any,
  mobs: any,
  bosses: any,
  npcs: any,
  location: any,
) => {
  const itemStyles = items.map((item: any) => {
    return `
        .items-${item.id} {
          background-position: ${item.backgroundPosition};
          width: 64px;
          height: 64px;
        }
      `;
  });

  const effectsStyles = effects.map((effect: any, idx: number) => {
    const size = effect.size;

    return `
        @keyframes animate-effect-${effect.type} {
          from {background-position: 0px -${idx * size}px;}
          to {background-position: -${size * effect.frames.length}px -${
      idx * size
    }px;}
        }
      `;
  });

  const mobStyles = mobs.map((mob: any, idx: number) => {
    return `
        @keyframes animate-stay-${mob.id} {
          from {background-position: ${mob.animateStay.from.backgroundPosition};}
          to {background-position: ${mob.animateStay.to.backgroundPosition};}
        }

        @keyframes animate-attack-${mob.id} {
          from {background-position: ${mob.animateAttack.from.backgroundPosition};}
          to {background-position: ${mob.animateAttack.to.backgroundPosition};}
        }

        @keyframes animate-die-${mob.id} {
          from {background-position: ${mob.animateDie.from.backgroundPosition};}
          50% {opacity:1;background-position: ${mob.animateDie.to.backgroundPosition};}
          80% {opacity:1;background-position: ${mob.animateDie.to.backgroundPosition};}
          100% {opacity:0;background-position: ${mob.animateDie.to.backgroundPosition};display:none}
        }
      `;
  });

  const bossesStyles = bosses.map((boss: any, idx: number) => {
    return `
        @keyframes animate-boss-stay-${boss.id} {
          from {background-position: ${boss.animateStay.from.backgroundPosition};}
          to {background-position: ${boss.animateStay.to.backgroundPosition};}
        }

        @keyframes animate-boss-attack-${boss.id} {
          from {background-position: ${boss.animateAttack.from.backgroundPosition};}
          to {background-position: ${boss.animateAttack.to.backgroundPosition};}
        }
        @keyframes animate-boss-skill-${boss.id} {
          from {background-position: ${boss.animateBossSkill.from.backgroundPosition};}
          to {background-position: ${boss.animateBossSkill.to.backgroundPosition};}
        }

        @keyframes animate-boss-die-${boss.id} {
          from {background-position: ${boss.animateDie.from.backgroundPosition};}
          50% {opacity:1;background-position: ${boss.animateDie.to.backgroundPosition};}
          80% {opacity:1;background-position: ${boss.animateDie.to.backgroundPosition};}
          100% {opacity:0;background-position: ${boss.animateDie.to.backgroundPosition};display:none}
        }
      `;
  });

  const npcStyles = npcs.map((npc: any, idx: number) => {
    return `
        @keyframes npc-animate-stay-${npc.id} {
          from {background-position: ${npc.animateStay.from.backgroundPosition};}
          to {background-position: ${npc.animateStay.to.backgroundPosition};}
        }
      `;
  });

  function getTransformProp(transform: string) {
    if (!transform) return "";
    return `transform: ${transform}`;
  }

  const locationStyles = location.map((loc: any, idx: number) => {
    const imgUrl = getCssImagePath(
      `components/game-location/maps/${loc.file_name}`,
    );
    return `
        .location-global-${loc.id} {
          ${getTransformProp(loc.transform)};
          ${`background-image: ${imgUrl};`}
        }
      `;
  });

  return injectStyles([
    npcStyles.join(""),
    locationStyles.join(""),
    bossesStyles.join(""),
    mobStyles.join(""),
    effectsStyles.join(""),
    itemStyles.join(""),
  ]);
};
