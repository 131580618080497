import styles from "./DialogTrade.module.scss";
import { ICON_STARS, TG_ICON } from "../../../../../services/base/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../../../services/store.service";
import { setCurrentTradeStep } from "../../../../../services/store/modal/modal.store";
import { ItemCell } from "../../ItemsPager/ItemsPager";
import { GetItemName } from "../../GetProps/GetProps";
import TgCoin from "../../TgCoin/TgCoin";
import { selectItem } from "../../../../../services/store/items/items.store";
import {
  changeItemsLevel,
  getItemsByType,
  parseItem,
} from "../../../../../services/base/item";
import TimeEnd from "../../TimeEnd/TimeEnd";

export function DialogSelectLvl({ itemList, onSelect, type }: any) {
  const [itemsByType, changeItemsByType]: any = useState([]);
  const [stars, changeStars]: any = useState([]);
  const { items } = useSelector((store: any) => store.items).data;

  useEffect(() => {
    console.log("itemList", itemList);

    // console.log("availableItems", itemsByType);
    changeItemsByType(getItemsByType(items, type));
  }, [type]);

  useEffect(() => {
    generateList(itemList);
  }, [itemList]);

  function generateList(list: any) {
    changeStars(
      new Array(101).fill(null).map((lvl, idx) => {
        let isDisabled = true;

        const level = idx;

        (list || []).forEach(({ item }: any) => {
          const [itemId, parsedItemLevel, count] = parseItem(item);
          // @ts-ignore
          if (level == parsedItemLevel) isDisabled = false;
        });

        return {
          isDisabled,
          level,
        };
      }),
    );
  }

  return (
    <div className={styles.DialogTradeTitleSelectLvl}>
      {stars.map((star: any, idx: any) => {
        return (
          <div
            key={idx}
            className={`${styles.DialogTradeTitleSelectLvlStar} ${
              star.isDisabled ? styles.StarDisabled : ""
            }`}
            onClick={() => {
              if (star.isDisabled) return;
              onSelect(star.level);
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: star.isDisabled ? ICON_STARS.fill : ICON_STARS.empty,
              }}
            ></div>
            <div className={`${styles.DialogTradeTitleSelectLvlStarText}`}>
              {star.level}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function DialogTradeBuyCards({ itemList, level, onSelect, type }: any) {
  const [itemsByType, changeItemsByType]: any = useState([]);
  const { items } = useSelector((store: any) => store.items).data;

  useEffect(() => {
    changeItemsByType(
      getItemsByType(items, type).map((itemId: string) => {
        let count = 0;

        itemList.forEach(({ item }: any) => {
          const [parsedItemId, parsedItemLevel, parsedItemCount] =
            parseItem(item);
          if (itemId === parsedItemId && level == parsedItemLevel) {
            count++;
          }
        });

        return {
          count,
          item: `${itemId}|${level}|1|${0}`,
        };
      }),
    );
  }, [itemList, type, level]);

  return (
    <div className="ItemsList">
      {(itemsByType || []).map((item: any, idx: number) => {
        return (
          <div
            key={idx}
            className={`${styles.DialogTradeBuyCard}`}
            onClick={() => {
              if (item.count === 0) return;
              onSelect(item.item);
            }}
          >
            <div
              className={`Item DisableHover ${styles.DialogTradeBuyCardCell}`}
            >
              <ItemCell item={item.item} withCount={false} withLevel={true} />
            </div>
            <div className="text-left">
              <GetItemName item={item.item} />
              <span className={styles.DialogTradeBuyCardCountExist}>
                {item.count} шт
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function DialogTradeBuyCardSelectList({
  items,
  selectedItem,
  type,
  withCountSelectedItem,
}: any) {
  const [filteredItems, changeFilteredItems]: any = useState([]);

  useEffect(() => {
    if (!selectedItem) {
      changeFilteredItems(items);
      return;
    }
    changeFilteredItems(
      items.filter(({ item }: any) => {
        const [selectedItemId, selectedItemLevel] = parseItem(selectedItem);
        const [itemId, itemLevel] = parseItem(item);
        return selectedItemId === itemId && selectedItemLevel === itemLevel;
      }),
    );
  }, [items, selectedItem, type]);

  if (!changeFilteredItems.length)
    return <div className="white-text text-center w-100">Нет данных</div>;

  return (
    <>
      <div className={`ItemsList ${styles.DialogTradeBuyCardSelectList} w-100`}>
        {filteredItems.map(
          ({ expireTime, item, price, tradeItemId }: any, idx: number) => {
            return (
              <div
                key={idx}
                className={`${styles.DialogTradeBuyCardSelectItem}`}
                onClick={() => {
                  store.dispatch(
                    selectItem({
                      item,
                      price,
                      tradeItemId,
                      type,
                      withCount: withCountSelectedItem,
                    }),
                  );
                }}
              >
                <div className={"Item DisableHover mb-2"}>
                  <ItemCell item={item} withCount={true} withLevel={true} />
                </div>
                {type !== "trade" ? (
                  <div className={`${styles.DialogTradeItemTime} mb-2`}>
                    <TimeEnd isUpdate={true} time={expireTime}></TimeEnd>
                  </div>
                ) : null}
                <div className={styles.DialogTradeBuyCardSelectItemPrice}>
                  <TgCoin value={price} />
                </div>
              </div>
            );
          },
        )}
      </div>
    </>
  );
}
