import React, { useEffect, useState } from "react";
import "./Loader.scss"; // Вынесем стили в отдельный файл
import { loadJson, preloadImages } from "../../../../services/base/list";

const IMAGE_RESOURCES = process.env.REACT_APP_IMAGES?.split(",") || [];
const JSON_RESOURCES = [
  "character/effects.json",
  "mobs/mobs.json",
  "mobs/bosses.json",
  "npc/npc.json",
  "levels/levels.json",
  "levels/village.json",
];

interface ILoader {
  isLoading?: boolean;
  onEndLoaded?: any;
}

const Loader: React.FC<ILoader> = ({ isLoading, onEndLoaded }) => {
  const [visible, setVisible] = useState(isLoading);
  const [currentCount, changeCurrentCount] = useState(0);
  const [loadText, changeLoadText] = useState("Загрузка ресурсов..");
  const [allResourcesCount] = useState(
    IMAGE_RESOURCES.length + JSON_RESOURCES.length,
  );

  useEffect(() => {
    if (isLoading) {
      setVisible(true);
    } else {
      const timer = setTimeout(() => setVisible(false), 700); // Время должно совпадать с длительностью анимации
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  useEffect(() => {
    Promise.all([
      preloadImages(IMAGE_RESOURCES, () => {
        changeLoadText("Загрузка архитектуры");
        changeCurrentCount(old => old + 1);
      }),
      loadJson(JSON_RESOURCES, () => {
        changeLoadText("Загрузка логики");
        changeCurrentCount(old => old + 1);
      }),
    ]).then(() => {
      changeLoadText("Вход в игру");
      onEndLoaded();
    });
  }, []);

  if (!visible) return null;

  return (
    <>
      <div className={`loading-page ${isLoading ? "show" : "hide"}`}>
        <div className="loading-page-container">
          <div className="loader-container">
            <div className="loader-animation"></div>
          </div>
          <div className="loader-tip">
            <div className="loader-tip__bar">
              <div
                className="loader-tip__bar__inner"
                style={{
                  width: `${(currentCount / allResourcesCount) * 100}%`,
                }}
              ></div>
            </div>
            <div className="loader-tip__text">
              Всего ресурсов: {currentCount} / {allResourcesCount}
            </div>
            <div className="loader-tip__text">{loadText}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Loader);
