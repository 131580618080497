import styles from "./DialogTrade.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../../../services/store.service";
import { setCurrentTradeStep } from "../../../../../services/store/modal/modal.store";

import {
  DialogSelectLvl,
  DialogTradeBuyCards,
  DialogTradeBuyCardSelectList,
} from "./TradeComponents";
import { getTradeList } from "../../../../../core/api/items/items.api";
import { IRequestResponse } from "../../../../../core/api/common";

function DialogTradeBuy({ type }: any) {
  const [currentLvl, changeCurrentLvl]: any = useState(null);
  const [showSelectedItemList, changeSelectedItem]: any = useState(null);
  const { selectedItem } = useSelector((store: any) => store.items);
  const [isLoading, changeLoading]: any = useState(true);

  const [items, changeItems]: any = useState([]);
  const { tradeStep } = useSelector((store: any) => store.modal);

  useEffect(() => {
    if (selectedItem === null) {
      changeLoading(true);

      setTimeout(() => {
        loadItems();
      }, 400);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (type === "potion" || type === "resource") {
      changeCurrentLvl(0);
      store.dispatch(setCurrentTradeStep(1));
    }

    loadItems();
  }, [type]);

  function loadItems() {
    changeLoading(true);
    getTradeList(type).then((iResp: IRequestResponse) => {
      if (iResp.hasError) return;
      const list = iResp.getData();

      changeItems(list);
      changeLoading(false);
    });
  }

  return (
    <>
      <div className={styles.DialogTradeTop}>
        <div className={styles.DialogTradeTitle}>{type}</div>
      </div>
      {/* Выбор уровня предмета */}
      {tradeStep === 0 && (
        <DialogSelectLvl
          itemList={items}
          onSelect={(lvl: number) => {
            changeCurrentLvl(lvl);
            store.dispatch(setCurrentTradeStep(1));
          }}
          type={type}
        />
      )}

      {/* Выбор предмета из списка */}
      {tradeStep === 1 && (
        <DialogTradeBuyCards
          itemList={items}
          level={currentLvl}
          onSelect={(selectedItem: number) => {
            changeSelectedItem(selectedItem);
            store.dispatch(setCurrentTradeStep(2));
          }}
          type={type}
        />
      )}

      {/* Выбор предмета из того что есть на аукционе */}
      {tradeStep === 2 && (
        <DialogTradeBuyCardSelectList
          items={items}
          selectedItem={showSelectedItemList}
          type={"trade"}
        />
      )}
    </>
  );
}

export default DialogTradeBuy;
