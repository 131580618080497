import { socketConnection } from "../../../../../core/api/common";
import { ACTION_TYPES_ID } from "../../../../../services/base/constants";
import { getFullDataMobFromShort } from "../../../../../services/base/mob";
import { updateActionSeed } from "../../../../../services/store/other/other.store";
import store from "../../../../../services/store.service";

export const doResponseScarecrow = (changeActionState: any, action: any) => {
  const socket: any = socketConnection();
  changeActionState((actions: any) => {
    const nActions = [...actions];
    nActions.forEach(act => {
      if (act.uniqId === action.uniqId) {
        act.mob = getFullDataMobFromShort(action.info);
        act.results = [...act.results, action];
      }
    });

    return nActions;
  });

  if (action?.allKilled) {
    socket.send("get action info", {
      id: ACTION_TYPES_ID.ScarecrowTime,
    });
    return;
  }
};

export const getActionInfoScarecrowTime = (
  changeActionState: any,
  action: any,
) => {
  if (action.reset) {
    store.dispatch(updateActionSeed());
    return;
  }

  changeActionState((actions: any) => {
    const nActions = [...actions];
    nActions.forEach((act, idx: number) => {
      if (act.id === ACTION_TYPES_ID.ScarecrowTime) act.data = action;

      if (
        action.mobs &&
        action.mobs.length &&
        act.id === ACTION_TYPES_ID.ScarecrowAttack
      ) {
        act.mob = action.mobs[idx];
        act.needGetInfo = false;
      }
    });

    return nActions;
  });
};
